<script setup lang="ts">
import type { Loan } from '@/api/models/loan/Loan'
import ConversionUtility from '@/utils/ConversionUtility'
import DateUtils from '@/utils/DateUtils'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import { ref } from 'vue'

const props = defineProps<{ loan: Loan }>()
const emit = defineEmits<{
  (e: 'submit', loan: Loan, action:string,voucher:number): void
}>()

const onLoanAction = () => {
  emit('submit', selectedLoan.value!!,action.value!!,voucherNo.value!!)
}
const selectedLoan = ref<Loan>()
const voucherNo = ref<number>()

const action = ref<string>()
</script>

<template>
  <Card class="cont">
    <template #header>
      <div class="holder-details">
        <div>
          <span class="text-heading">Application ID: </span>
          <span class="text">{{ props.loan.applicationId }}<br /></span>
        </div>
        <div>
          <span class="text-heading">Principal Amount: </span>
          <span class="text"
            >{{ ConversionUtility.toStringRupeesConversion(props.loan.loanAmount) }}<br
          /></span>
        </div>
        <div>
          <span class="text-heading">Customer ID: </span>
          <span class="text">{{ props.loan.customerId }}<br /></span>
        </div>
        <div>
          <span class="text-heading">Created On: </span>
          <span class="text">{{ ConversionUtility.toDateFormat(props.loan.createdAt) }}<br /></span>
        </div>
        <div>
          <span class="text-heading">Status: </span>
          <Tag v-if="props.loan.status == 'APPROVED'" severity="success">
            {{ props.loan.status }}
          </Tag>
          <Tag v-else-if="props.loan.status == 'REJECTED'" severity="danger">
            {{ props.loan.status }}
          </Tag>
          <Tag v-else severity="warn">
            {{ props.loan.status }}
          </Tag>
        </div>
        <div>
          <span class="text-heading">Type: </span>
          <span class="text">{{ props.loan.loanType }}<br /></span>
        </div>
      </div>
      <Divider style="margin-top: 0" />
    </template>
    <template #content>
      <section class="loan-approve-container">
        <Panel header="Loan Details" toggleable collapsed>
          <Divider style="margin-top: 0" />
          <div class="selectLoanSection">
            <div>
              <span class="text-heading">Tenure: </span>
              <span class="text">
                {{
                  Math.floor(props.loan.tenureMonths / 12) > 0
                    ? Math.floor(props.loan.tenureMonths / 12) +
                      (Math.floor(props.loan.tenureMonths / 12) === 1 ? ' Year ~ ' : ' Years ~ ') +
                      (props.loan.tenureMonths % 12) +
                      ' Months'
                    : (props.loan.tenureMonths % 12) + ' Months'
                }}<br />
              </span>
            </div>
            <div>
              <span class="text-heading">Loan End Date: </span>
              <span class="text"
                >{{
                  props.loan.tenureMonths
                    ? new Date(
                        DateUtils.getFutureDateInMilliseconds(
                          props.loan.emiDate!!,
                          props.loan.tenureMonths + 1,
                          new Date(props.loan.createdAt!!)
                        )
                      ).toDateString()
                    : '-'
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Interest Rate: </span>
              <span class="text">{{ props.loan.interestRate }} %<br /></span>
            </div>
            <div>
              <span class="text-heading">Created By: </span>
              <span class="text">{{ props.loan.createdBy.empId }}<br /></span>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center">
              <span class="text-heading">Guarantors : </span>
              <span
                v-for="guarantor in props.loan.guarantors"
                :key="guarantor"
                class="text chip-container m-2"
                ><Chip>{{ guarantor }}</Chip></span
              >
            </div>
            <div style="display: flex; flex-direction: row; align-items: center">
              <span class="text-heading">Directors Recommendation : </span>
              <span class="text chip-container m-2"
                ><Chip>{{ props.loan.directorId > 0 ? props.loan.directorId : ` -` }}</Chip></span
              >
            </div>
          </div>
        </Panel>
        <Panel header="Amount Details" toggleable collapsed>
          <Divider style="margin-top: 0" />
          <div class="flex flex-col gap-5">
            <Panel class="flex flex-col">
              <span class="text-base flex">Total Deductions :</span>
              <Divider />
              <div class="calculation-container">
                <span class="text-heading">Principal Amount: </span>
                <span class="text"
                  >{{ ConversionUtility.toStringRupeesConversion(props.loan.loanAmount) }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading"
                  >Shareholder Transfer Amount @ ({{ props.loan.shareTransferPercentage }} %)
                </span>
                <span class="text"
                  >-
                  {{
                    ConversionUtility.toStringRupeesConversion(
                      props.loan.shareHolderTransferAmount
                    )
                  }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading">Processing Fees: </span>
                <span class="text"
                  >- {{ ConversionUtility.toStringRupeesConversion(props.loan.processingFees) }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading">Building Fees: </span>
                <span class="text"
                  >- {{ ConversionUtility.toStringRupeesConversion(props.loan.buildingFees) }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading">Miscellaneous Fees: </span>
                <span class="text"
                  >- {{ ConversionUtility.toStringRupeesConversion(props.loan.miscFees) }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading">Share Fees: </span>
                <span class="text"
                  >- {{ ConversionUtility.toStringRupeesConversion(props.loan.shareFees) }}<br
                /></span>
              </div>
              <Divider />
              <div class="calculation-container">
                <span class="text-heading">Transfer To SB: </span>
                <span class="text"
                  >{{ ConversionUtility.toStringRupeesConversion(props.loan.amountToTransferToSB)
                  }}<br
                /></span>
              </div>
            </Panel>
            <Panel class="flex flex-col">
              <span class="text-base flex">Total Loan Amount:</span>
              <Divider />
              <div class="calculation-container">
                <span class="text-heading">Principal Amount (A): </span>
                <span class="text"
                  >{{ ConversionUtility.toStringRupeesConversion(props.loan.loanAmount) }}<br
                /></span>
              </div>
              <div class="calculation-container">
                <span class="text-heading"
                  >Total Interest Amount @ {{ props.loan.interestRate }} % (B):
                </span>
                <span class="text"
                  >{{ ConversionUtility.toStringRupeesConversion(props.loan.interestAmount) }}<br
                /></span>
              </div>
              <Divider />
              <div class="calculation-container">
                <span class="text-heading">Total Payable Amount (A + B): </span>
                <span class="text"
                  >{{
                    ConversionUtility.toStringRupeesConversion(
                      props.loan.loanAmount + props.loan.interestAmount
                    )
                  }}<br
                /></span>
              </div>
            </Panel>
          </div>
        </Panel>
        <Panel header="Customer Details" collapsed toggleable>
          <Divider style="margin-top: 0" />
          <div class="selectLoanSection">
            <div>
              <span class="text-heading">Customer ID: </span>
              <span class="text">{{ props.loan.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading"
                >{{
                  props.loan.employerName
                    ? `Employer Name`
                    : props.loan.businessDetails
                      ? `Business Details`
                      : props.loan.agricultureDetails
                        ? `Agriculture Details`
                        : `Something Went Wrong`
                }}:
              </span>
              <span class="text"
                >{{
                  props.loan.employerName ??
                  props.loan.businessDetails ??
                  props.loan.agricultureDetails
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Income Per Annum: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(props.loan.incomePerAnnum) }}<br
              /></span>
            </div>
            <div v-if="props.loan.incomePerMonth">
              <span class="text-heading">Income Per Month: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(props.loan.incomePerMonth) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Collateral Details: </span>
              <span class="text">{{ props.loan.collateralDetails }}<br /></span>
            </div>
            <div style="display: flex; flex-direction: column; align-items: center">
              <span class="text-heading">Documents Submitted: </span>
              <span
                class="text chip-container"
                style="flex-direction: column; justify-content: center; align-items: center"
                ><Chip v-for="doc in props.loan.documentSubmitted" :key="doc" class="m-2">{{
                  doc
                }}</Chip
                ><br
              /></span>
            </div>
          </div>
        </Panel>
      </section>
      <div class="operations" v-if="props.loan.status === 'PENDING'">
        <CustomButton
          title="Reject Loan"
          icon="close"
          severity="danger"
          @click="
            () => {
              selectedLoan = props.loan
              action = 'rejectLoan'
              onLoanAction()
            }
          "
        />
        <CustomButton
          title="Approve Loan"
          icon="check"
          severity="success"
          @click="
            () => {
              selectedLoan = props.loan
              action = 'approveLoan'
              onLoanAction()
            }
          "
        />
      </div>
      <div class="operations" v-if="props.loan.status === 'APPROVED'">
        <InputNumber
          :min="1"
          v-model="voucherNo"
          :use-grouping="false"
          placeholder="Voucher No"
          class="w-1/4"
        />
        <CustomButton
          title="Disburse Loan"
          icon="times"
          @click="
            () => {
              selectedLoan = props.loan
              action = 'disburseLoan'
              onLoanAction()
            }
          "
        />
      </div>
    </template>
  </Card>
</template>

<style scoped>
.loan-approve-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.selectLoanSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 1.5rem;
  column-gap: 0;
}

.operations {
  margin-top: 2rem;
  justify-content: end;
  gap: 1rem;
}

.cont {
  border-radius: 10px;
  border: 1px rgb(224, 224, 224) solid;
}

.chip-container {
  gap: 0.5rem;
}

.calculation-container {
  display: flex;
  justify-content: space-between;
}

.text-heading {
  font-size: medium;
}
</style>
