export default class ConversionUtility {
  static toStringRupeesConversion(value: number) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(
      this.toRuppesCoversion(value)
    )
  }
  static toStringRupees(value: number) {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)
  }
  static toRuppesCoversion(paise: number) {
    return paise / 100
  }
  static toPaiseConversion(rs: number) {
    return parseFloat(rs.toFixed(2)) * 100
  }
  static toDateFormat(value: number){
    return `${new Date(value).getDate()} ${new Date(value).toDateString().slice(4, 7)} ${new Date(value).getUTCFullYear()}`
  }
  static toDateAndTimeFormat(value: number){
    return `${new Date(value).getDate()} ${new Date(value).toDateString().slice(4, 7)} ${new Date(value).getUTCFullYear() } ${new Date(value).toLocaleString('en-IN', { hour: 'numeric', minute: 'numeric', hour12: true })}`
  }
}
