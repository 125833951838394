<script setup lang="ts">
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import SearchComponent from '@/components/SearchComponent.vue'
import { ref } from 'vue'
import NotFound from '@/assets/not_found.svg'
import ApiService from '@/api/ApiService'
import { useToast } from 'primevue/usetoast'
import type { ToastMessageOptions } from 'primevue/toast'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { CreateAccountRequest } from '@/api/models/account/CreateAccountRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import type { Customer } from '@/api/models/customer/Customer'
import type { AccountCheckResponseData } from '@/api/models/account/AccountCheckResponse'
import Storage from '@/utils/Storage'
import ConversionUtility from '@/utils/ConversionUtility'
import ValidationUtility from '@/utils/ValidationUtility'
import router from '@/router'

const toast = useToast()
const searchQuery = ref<string>('')
const initDepositAmount = ref<number>()
const customer = ref<Customer | undefined>(undefined)
const accountCheckResponseData = ref<AccountCheckResponseData | undefined>(undefined)
const isLoading = ref<boolean>(false)
const isAccountExists = ref<boolean>(false)

const handelCustomerSearch = (searchResponse: string) => {
  if (!ValidationUtility.validateNumber(searchResponse)) {
    showToast('Invalid search query', 'Input should be a number', 'error')
    return
  }
  searchQuery.value = searchResponse
  getCustomer()
}

function getCustomer() {
  if (!searchQuery.value) {
    showToast('Search query is empty/blank', 'Validation failed', 'error')
    return
  }
  isLoading.value = true
  ApiService.checkAccountAndGetCustomer(Number(searchQuery.value))
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        if (!response.data) {
          showToast('Customer not found', response.error ?? 'Cannot find customer', 'error')
          return
        }
        isAccountExists.value = response.data.account != null
        accountCheckResponseData.value = response.data
        customer.value = response.data.customer
      } else {
        customer.value = undefined
        showToast('Something went worng!', response.error ?? 'Cannot find customer', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customer', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

// function createAccount() {
//   if (!initDepositAmount.value) {
//     showToast('Initial deposit amount cannot be empty/blank', 'Validation failed', 'error')
//     return
//   }
//   isLoading.value = true

//   const createBy: CreatedBy = {
//     empId: Storage.getEmpId()!!,
//     createdAt: Date.now()
//   }

//   const createAccountRequest: CreateAccountRequest = {
//     customerId: customer.value?.customerId!!,
//     initDeposit: ConversionUtility.toPaiseConversion(initDepositAmount.value),
//     createdAt: Date.now(),
//     src: 'Initial Deposit while creating account',
//     createdBy: createBy
//   }

//   ApiService.createAccount(createAccountRequest)
//     .then((response) => {
//       setTimeout(() => {
//         if (response.statusCode == 401) {
//           Storage.clearData()
//           router.push({
//             name: 'login'
//           })
//         }
//       }, 2000)
//       accountCheckResponseData.value!!.account = response.data?.account
//       isAccountExists.value = true
//       showToast(
//         'Account created successfully',
//         `Account with Id ${response.data?.account?.accountId} created`,
//         'success'
//       )
//     })
//     .catch((error) => {
//       showToast('Failed to create account', error, 'error')
//     })
//     .finally(() => {
//       isLoading.value = false
//     })
// }

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Toast class="error-p-toast" />
    <Card class="create-card">
      <template #content>
        <SearchComponent
          title="Create Savings Bank Accounts"
          subtitle="Create a new savings bank account for a existing customer (Eg. - 100001)"
          placeholder="Search Customer by customer Id"
          @search-click="handelCustomerSearch"
        />
        <div v-if="customer == undefined && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip
            label="No customers found for the provided search query!"
            style="font-weight: 600"
          />
        </div>
        <div v-if="customer != undefined && !isLoading">
          <div class="cont">
            <div class="account_holder_details">
              <div>
                <span class="text-heading">Cust ID: </span>
                <span class="text">{{ customer.customerId }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Name: </span>
                <span class="text">{{ `${customer.firstName} ${customer.lastName}` }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Phone No: </span>
                <span class="text">{{ `+91-${customer.phoneNumber}` }}<br /></span>
              </div>
              <div>
                <span class="text-heading">PAN No: </span>
                <span class="text">{{ customer.panNumber }}<br /></span>
              </div>
              <div>
                <span class="text-heading">City/Town: </span>
                <span class="text">{{ customer.city }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Pincode: </span>
                <span class="text">{{ customer.pincode }}<br /></span>
              </div>
            </div>
            <div class="account_details">
              <InputNumber
                v-model="initDepositAmount"
                b
                class="input-text"
                :disabled="isAccountExists"
                placeholder="Initial Deposit Amount"
                mode="currency"
                currency="INR"
                :min-fraction-digits="0"
                locale="en-US"
                fluid
              />
              <!-- <CustomButton
                icon="add"
                title="Create SB Account"
                @click="createAccount"
                :disabled="isAccountExists"
              /> -->
            </div>
          </div>
          <div class="cont" v-if="accountCheckResponseData?.account != undefined && !isLoading">
            <div class="account_holder_details">
              <div>
                <span class="text-heading">Account ID: </span>
                <span class="text">{{ accountCheckResponseData?.account?.accountId }}</span>
              </div>
              <div>
                <span class="text-heading">Balance: </span>
                <span class="text">{{
                  ConversionUtility.toStringRupeesConversion(
                    accountCheckResponseData?.account?.balance
                  )
                }}</span>
              </div>
              <div>
                <span class="text-heading">Created On: </span>
                <span class="text">{{
                  ConversionUtility.toDateFormat(accountCheckResponseData?.account?.createdAt)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
      </template>
    </Card>
  </main>
</template>

<style scoped>
.main {
  overflow-y: scroll;
}
.create-card {
  width: 100%;
  margin-bottom: 4rem;
}
.cont {
  margin: 1rem 0;
  background-color: white;
  padding: 1.5rem 1.5rem;
  border-radius: 18px;
  border: solid rgba(0, 0, 0, 0.4) 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-text {
  width: 20%;
  height: 50px;
  margin-right: 20px;
  --p-inputtext-focus-border-color: rgb(50, 95, 186);
}

.account_holder_details {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.account_details {
  padding: 16px;
  border-radius: 16px;
  background-color: #d9e2ff;
  display: flex;
  justify-content: end;
}

.text-heading {
  font-size: small;
}

.text {
  font-weight: 600;
  font-size: large;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  margin: 20px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(104, 103, 103);
  border-radius: 4px;
}
</style>
