<script setup lang="ts">
import { ref } from 'vue'
import type { SearchProps } from '@/types/SearchProps'
import Card from 'primevue/card'
import CustomButton from './CustomComponent/CustomButton.vue'

const props = defineProps<SearchProps>()
const emit = defineEmits<{
  (e: 'search', searchText: string): void
  (e: 'search-click', searchText: string): void
}>()

const searchText = ref('')

const onSearchClick = () => {
  emit('search-click', searchText.value)
}

const onEnterPress = (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    onSearchClick()
  }
}

</script>
<template>
  <Card style="box-shadow:none">
    <template #title>
      <h2 class="text-3xl font-bold">{{ props.title }}</h2>
    </template>
    <template #subtitle>{{ props.subtitle }}</template>
    <template #content>
      <Divider />
      <div class="bar">
        <InputText class="searchcomponentinput" :placeholder="props.placeholder" v-model="searchText" @keydown.enter="onEnterPress" @input="searchText = searchText.trim()"></InputText>
        <CustomButton title="search" icon="search" @click="onSearchClick"/>
      </div>
    </template>
  </Card>

</template>
<style>
.bar{
  height: fit-content ;
  display: flex;
  flex-flow: row;
  gap: 2rem;
}
.searchcomponentinput{
  width: 100%;
  height: 40px;
}
.p-card-title {
  text-align: start;

  h2 {
    margin: 0px;
  }
}

.p-card-subtitle {
  text-align: start;
  font-size: small;
}
</style>
