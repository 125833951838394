<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type CashFlowDetails from '@/api/models/cashflow/CashFlowDetails'
import type DeleteCashflowRequest from '@/api/models/cashflow/DeleteCashFlowRequest'
import type GetCashFlowRequest from '@/api/models/cashflow/GetCashFlowRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import router from '@/router'
import ConversionUtility from '@/utils/ConversionUtility'
import Storage from '@/utils/Storage'
import ValidationUtility from '@/utils/ValidationUtility'
import type { ToastMessageOptions } from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'

const isLoading = ref<boolean>(false)
const confirmDialog = useConfirm()
const toast = useToast()

const expensesType = ref([
  { name: 'Salary and Wages' },
  { name: 'Rent' },
  { name: 'Utilities' },
  { name: 'Internet and Communication' },
  { name: 'Maintenance and Repairs' },
  { name: 'Loan Loss Provisions' },
  { name: 'Depreciation' },
  { name: 'Office Supplies' },
  { name: 'Security Expenses' },
  { name: 'Insurance' },
  { name: 'Advertising and Marketing' },
  { name: 'Legal and Professional Fees' },
  { name: 'Transportation' },
  { name: 'Miscellaneous' }
])
const selectedMonth = ref<Date>()
const addDetails = ref(false)
const showDetails = ref(false)
const addingCategory = ref()
const itemType = ref()
const amount = ref<number>()
const voucher = ref<number>()
const transacAt = ref<Date>()
const uniqueId = ref<number>()

const incomeAndExpenses = ref<CashFlowDetails[] | undefined>([])
const incomes = ref<CashFlowDetails[]>([])
const expenses = ref<CashFlowDetails[]>([])
incomes.value = incomeAndExpenses.value?.filter((income) => income.category === 'Income')!
expenses.value = incomeAndExpenses.value?.filter((expense) => expense.category === 'Expense')!

function getCashFlow() {
  if (!selectedMonth.value) {
    showToast('Month cannot be empty', 'Select month', 'error')
    return
  }

  isLoading.value = true
  const month: GetCashFlowRequest = {
    month: new Date(selectedMonth.value.setHours(10, 0, 0, 0)).getTime().toString()
  }

  ApiService.getCashFlow(month)
    .then((response) => {
      if (response.statusCode == 200) {
        incomeAndExpenses.value = response.data
        incomes.value = incomeAndExpenses.value?.filter((income) => income.category === 'Income')!
        expenses.value = incomeAndExpenses.value?.filter(
          (expense) => expense.category === 'Expense'
        )!
      } else if (response.statusCode == 404) {
        incomeAndExpenses.value = []
        incomes.value = []
        expenses.value = []
      } else {
        showToast('Something is wrong', 'Please try again', 'error')
      }
    })
    .catch((error) => {
      showToast('Something wrong', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      showDetails.value = true
    })
}

function addNew(type: any) {
  addingCategory.value = type
  addDetails.value = true
}

function addCashFlow() {
  if (!itemType.value) {
    showToast(addingCategory.value + ' type cannot be blank', 'Please add/select correct', 'error')
    return
  }

  if (!ValidationUtility.validateString(itemType.value.toString()) || itemType.value.length < 4) {
    showToast('Invalid income type', 'Please enter valid income type(it should be min 4 letters)', 'error')
    return
  }

  if (!Number(amount.value)) {
    showToast(addingCategory.value + ' amount cannot be blank', 'Please add correct value', 'error')
    return
  }

  if (!voucher.value) {
    showToast(
      addingCategory.value + ' voucher no cannot be blank',
      'Please add correct value',
      'error'
    )
    return
  }

  if (voucher.value?.toString().length > 8) {
    showToast('Voucher no should be 8 digits', 'Please add correct value', 'error')
    return
  }

  if (!Number(transacAt.value)) {
    showToast(addingCategory.value + ' date cannot be blank', 'Please select correct date', 'error')
    return
  }

  isLoading.value = true

  const createdBy: CreatedBy = {
    empId: Storage.getEmpId()!,
    createdAt: new Date().getTime()
  }

  uniqueId.value = Number(
    new Date().getFullYear().toString() +
      String(new Date().getMonth() + 1).padStart(2, '0') +
      String(new Date().getDate()).padStart(2, '0') +
      Math.floor(1000 + Math.random() * 9000)
  )

  const request: CashFlowDetails = {
    cashFlowId: uniqueId.value,
    category: addingCategory.value,
    transactionAt: new Date(transacAt.value!).getTime(),
    month: new Date(selectedMonth.value!!.setHours(10, 0, 0, 0)).getTime(),
    type: itemType.value,
    amount: ConversionUtility.toPaiseConversion(Number(amount.value)),
    voucherNumber: voucher.value,
    createdAt: new Date().getTime(),
    createdBy: createdBy
  }

  ApiService.addCashFlow(request)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast('Added Successfully', addingCategory.value + ' Added', 'success')
      }
    })
    .catch((error) => {
      showToast(error, 'Something is wrong', 'error')
    })
    .finally(() => {
      isLoading.value = false
      addDetails.value = false
      clearFields()
      getCashFlow()
    })
}

function clearFields() {
  itemType.value = ''
  voucher.value = undefined
  amount.value = undefined
  transacAt.value = undefined
}

function deleteItem(itemId: number) {
  confirmDialog.require({
    message: `Are you sure want to delete?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      isLoading.value = true
      const request: DeleteCashflowRequest = {
        id: itemId
      }
      ApiService.deleteCashFlow(request)
        .then((response) => {
          if (response.statusCode == 200) {
            showToast('Successfull', 'Item deleted successfully', 'success')
          } else {
            showToast('Error', 'Something is wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Error', error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          getCashFlow()
        })
    }
  })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <Toast class="error-p-toast" />
  <main class="main">
    <Card class="search-card">
      <template #title>
        <h2 class="title-right text-3xl font-bold">Income and Expenses</h2>
      </template>
      <template #content>
        <Divider />
        <div
          style="margin: 30px; display: grid; grid-template-columns: repeat(4, 2fr); gap: 3.5rem"
        >
          <FloatLabel>
            <label for="selectedDate" class="mandatory">Select Month and Year</label>
            <DatePicker
              v-model="selectedMonth"
              id="selectedDate"
              class="input-text"
              view="month"
              dateFormat="MM/yy"
              :minDate= "new Date(new Date().setMonth(new Date().getMonth()-6))"
              :maxDate="new Date()"
            />
          </FloatLabel>
          <CustomButton title="get" @click="getCashFlow()" class="cstmbtn" />
        </div>

        <Fieldset v-if="showDetails" style="text-align: left; margin: 10px" legend="Other Incomes">
          <DataTable v-if="incomes.length > 0" :value="incomes" tableStyle="min-width: 50rem">
            <Column header="Month-Year" style="width: 25%">
              <template #body="">
                {{ selectedMonth?.toDateString().slice(4, 7) }} -
                {{selectedMonth?.getFullYear() }}
              </template>
            </Column>
            <Column header="Income Type" style="width: 25%">
              <template #body="slotProps">
                {{ slotProps.data.type }}
              </template>
            </Column>
            <Column header="Income Amount" style="width: 25%">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.amount) }}
              </template>
            </Column>
            <Column header="Voucher No" style="width: 25%">
              <template #body="slotProps">
                {{ slotProps.data.voucherNumber }}
              </template>
            </Column>
            <Column header="Action">
              <template #body="slotProps">
                <Button
                  icon="fas fa-trash"
                  class="p-button-rounded p-button-text p-button-danger"
                  @click="deleteItem(slotProps.data.cashFlowId)"
                />
              </template>
            </Column>
          </DataTable>
          <label v-else style="font-size: 12px; color: #181818; font-style: italic"
            >No Incomes added for this month yet.</label
          >
          <CustomButton title="Add Income" @click="addNew('Income')" style="margin: 20px" />
        </Fieldset>

        <Fieldset v-if="showDetails" style="text-align: left; margin: 10px" legend="Other Expenses">
          <DataTable v-if="expenses.length > 0" :value="expenses" tableStyle="min-width: 50rem">
            <Column header="Month-Year" style="width: 25%">
              <template #body="">
                {{ selectedMonth?.toDateString().slice(4, 7) }} -
                {{ selectedMonth?.getFullYear() }}
              </template>
            </Column>
            <Column header="Expense Type" style="width: 25%">
              <template #body="slotProps">
                {{ slotProps.data.type }}
              </template>
            </Column>
            <Column header="Expense Amount" style="width: 25%">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.amount) }}
              </template>
            </Column>
            <Column header="Voucher No" style="width: 25%">
              <template #body="slotProps">
                {{ slotProps.data.voucherNumber }}
              </template>
            </Column>
            <Column header="Action">
              <template #body="slotProps">
                <Button
                  icon="fas fa-trash"
                  class="p-button-rounded p-button-text p-button-danger"
                  @click="deleteItem(slotProps.data.cashFlowId)"
                />
              </template>
            </Column>
          </DataTable>
          <label v-else style="font-size: 12px; color: #181818; font-style: italic"
            >No Expenses added for this month yet.</label
          >
          <CustomButton title="Add Expense" @click="addNew('Expense')" style="margin: 20px" />
        </Fieldset>

        <Dialog
          v-model:visible="addDetails"
          :draggable="false"
          modal
          :style="{ width: '40rem' }"
          :closable="false"
        >
          <span class="dialog-header">Add new {{ addingCategory }}</span>
          <Divider />
          <div class="form">
            <FloatLabel v-if="addingCategory == 'Expense'">
              <Select
                v-model="itemType"
                inputId="expenseType"
                :options="expensesType"
                optionLabel="name"
                option-value="name"
                class="dropdown-select"
                overlayClass="dropdown-overlay"
              />
              <label for="expenseType" class="mandatory">Type of {{ addingCategory }}</label>
            </FloatLabel>

            <FloatLabel v-else>
              <InputText id="incomeType" v-model="itemType" class="input-text" />
              <label for="incomeType" class="mandatory">Type of {{ addingCategory }}</label>
            </FloatLabel>

            <FloatLabel>
              <InputNumber
                id="incomeAmount"
                input-id="locale-indian"
                locale="en-IN"
                mode="currency"
                currency="INR"
                v-model="amount"
                :maxFractionDigits="0"
                class="input-text"
                size="large"
                :min="0"
              />
              <label for="incomeAmount" class="mandatory">{{ addingCategory }} Amount</label>
            </FloatLabel>
            <FloatLabel>
              <InputNumber
                id="incomeVoucher"
                v-model="voucher"
                class="input-text"
                :min="1"
                :useGrouping="false"
              />
              <label for="incomeVoucher" class="mandatory">Voucher Number</label>
            </FloatLabel>
            <FloatLabel>
              <label for="transacAT" class="mandatory">Transaction Date</label>
              <DatePicker
                v-model="transacAt"
                id="transacAT"
                class="input-text"
                :minDate="new Date(new Date().getFullYear(), selectedMonth!!.getMonth(), 1)"
                :maxDate="new Date().getMonth()==selectedMonth!!.getMonth()?new Date():new Date(new Date().getFullYear(), selectedMonth!!.getMonth()+1,0)"
                dateFormat="dd/mm/yy"
              />
            </FloatLabel>
          </div>
          <template #footer>
            <CustomButton
              :outlined="true"
              title="close"
              icon="times"
              @click="()=>{
                addDetails=false
                clearFields()
              }"
            />
            <CustomButton title="Save" icon="check" @click="addCashFlow" />
          </template>
        </Dialog>
      </template>
    </Card>
  </main>
  <div v-if="isLoading">
    <LoadingComponent
      text="Please wait while we process the request!"
      :dialogVisiblity="isLoading"
    />
  </div>
  <ConfirmDialog :draggable="false" :closable="false" />
</template>
<style>
.main {
  padding-bottom: 80px;
}

.form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 2.5rem;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10%;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.input-text {
  height: 45px;
}

.dropdown-select .p-dropdown-item {
  color: rgb(50, 95, 186);
}

.dropdown-overlay {
  --p-select-overlay-color: rgb(50, 95, 186);
}

.dropdown-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue;
}
</style>