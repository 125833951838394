import type { AmortizationEMISchedule } from "@/models/AmortizationEMISchedule";
import DateUtils from "./DateUtils";
import { SiteSettings } from "./SiteSettings";

export class InterestAndTotalCalculator {
    public static calculateLoanInterest(
        amount: number,
        rate: number,
        startDate: number,
        endDate: number
    ): number {
        //simple interest formula needs to be fixed
        if (SiteSettings.getLoanInterestType() === 'Simple') {
            return 0;
        }

        if (SiteSettings.getLoanInterestType() === 'Compound') {
            return (amount * Math.pow(1 + rate / 100, DateUtils.getYearsDifference(startDate,endDate)))-amount ;
        }
        return 0
    }
    public static calculateEMI(principal:number, annualInterestRate:number, loanTenureMonths:number) {
        const monthlyInterestRate = (annualInterestRate / 100) / 12;
        
        const emi = (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTenureMonths)) / 
                    (Math.pow(1 + monthlyInterestRate, loanTenureMonths) - 1);
        
        return emi;
    }
    
    public static generateAmortizationSchedule(principal:number, annualInterestRate:number, loanTenureMonths:number) {
        const emi = this.calculateEMI(principal, annualInterestRate, loanTenureMonths);
        const monthlyInterestRate = (annualInterestRate / 100) / 12;
        
        let outstandingPrincipal = principal;
        let amortizationSchedule : AmortizationEMISchedule[] = [];
        
        for (let month = 1; month <= loanTenureMonths; month++) {
            let interestForMonth = outstandingPrincipal * monthlyInterestRate;
            let principalForMonth = emi - interestForMonth;
            
            amortizationSchedule.push({
                month: month,
                emi: Number(emi.toFixed(2)),
                interest: Number(interestForMonth.toFixed(2)),
                principal: Number(principalForMonth.toFixed(2)),
                outstandingPrincipal: Number((outstandingPrincipal - principalForMonth).toFixed(2))
            });
            
            outstandingPrincipal -= principalForMonth;
        }
        
        return amortizationSchedule;
    }

    public static generateAmortizationScheduleWithPrepayment(principal:number, annualInterestRate:number, loanTenureMonths:number, prepaymentAmount:number, prepaymentMonth:number) {
        const emi = this.calculateEMI(principal, annualInterestRate, loanTenureMonths);
        const monthlyInterestRate = (annualInterestRate / 100) / 12;
        
        let outstandingPrincipal = principal;
        let amortizationSchedule : AmortizationEMISchedule[] = [];
        
        for (let month = 1; month <= loanTenureMonths; month++) {
            if (month === prepaymentMonth) {
                outstandingPrincipal -= prepaymentAmount; // Apply lump sum prepayment
            }
    
            let interestForMonth = outstandingPrincipal * monthlyInterestRate;
            let principalForMonth = emi - interestForMonth;
            amortizationSchedule.push({
                month: month,
                emi: Number(emi.toFixed(2)),
                interest: Number(interestForMonth.toFixed(2)),
                principal: Number(principalForMonth.toFixed(2)),
                outstandingPrincipal: Number((outstandingPrincipal - principalForMonth).toFixed(2))
            });
            
            // Update outstanding principal for next month
            outstandingPrincipal -= principalForMonth;
    
            // Stop if the loan is paid off
            if (outstandingPrincipal <= 0) {
                break;
            }
        }
        
        return amortizationSchedule;
    }
  
    public static recalculateEMIAfterPrepayment(newPrincipal:number, annualInterestRate:number, remainingTenureMonths:number) {
        const monthlyInterestRate = (annualInterestRate / 100) / 12;
        
        const newEmi = (newPrincipal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, remainingTenureMonths)) / 
                    (Math.pow(1 + monthlyInterestRate, remainingTenureMonths) - 1);
        
        return newEmi;
    }
  
  public static calculatePigmyInterest(
        amount: number,
        rate: number,
        startDate: number,
        endDate: number
    ): number {
        //simple interest formula needs to be fixed
        if (SiteSettings.getPigmyInterestType() === 'Simple') {
            return ((amount * (DateUtils.getYearsDifference(startDate, endDate))*rate) /100)
        }

        if (SiteSettings.getPigmyInterestType() === 'Compound') {
            return (amount * Math.pow(1 + rate / 100, DateUtils.getYearsDifference(startDate,endDate)))-amount ;
        }
        return 0
    }
}