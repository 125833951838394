import { createApp } from 'vue'
import './index.css'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import Card from 'primevue/card'
import Button from 'primevue/button'
import Divider from 'primevue/divider'
import FloatLabel from 'primevue/floatlabel'
import Toast from 'primevue/toast'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import Password from 'primevue/password'
import ProgressBar from 'primevue/progressbar'
import ToastService from 'primevue/toastservice'
import Avatar from 'primevue/avatar'
import Menubar from 'primevue/menubar'
import Aura from '@primevue/themes/aura'
import Select from 'primevue/select'
import DatePicker from 'primevue/datepicker'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputNumber from 'primevue/inputnumber'
import Tag from 'primevue/tag'
import Dialog from 'primevue/dialog'
import Image from 'primevue/image'
import Chip from 'primevue/chip'
import Popover from 'primevue/popover'
import { LottieAnimation } from 'lottie-web-vue'
import Drawer from 'primevue/drawer'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import ToggleButton from 'primevue/togglebutton';
import ToggleSwitch from 'primevue/toggleswitch';
import SelectButton from 'primevue/selectbutton';
import Panel from 'primevue/panel';
import Fieldset from 'primevue/fieldset'
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import Stepper from 'primevue/stepper';
import StepList from 'primevue/steplist';
import StepPanels from 'primevue/steppanels';
import StepItem from 'primevue/stepitem';
import Step from 'primevue/step';
import StepPanel from 'primevue/steppanel';
import RadioButton from 'primevue/radiobutton';
import MultiSelect from 'primevue/multiselect';
import Slider from 'primevue/slider';

const app = createApp(App)
app.use(ToastService)
app.use(ConfirmationService)
app.component('Tag', Tag)
app.component('Dialog', Dialog)
app.component('Card', Card)
app.component('Button', Button)
app.component('Divider', Divider)
app.component('FloatLabel', FloatLabel)
app.component('ProgressBar', ProgressBar)
app.component('Toast', Toast)
app.component('InputText', InputText)
app.component('InputMask', InputMask)
app.component('Password', Password)
app.component('Avatar', Avatar)
app.component('Menubar', Menubar)
app.component('Select', Select)
app.component('DatePicker', DatePicker)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('InputNumber', InputNumber)
app.component('LottieAnimation', LottieAnimation)
app.component('Image', Image)
app.component('Chip', Chip)
app.component('Popover', Popover)
app.component('Drawer', Drawer)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ToggleButton', ToggleButton)
app.component('SelectButton', SelectButton)
app.component('Panel', Panel)
app.component('Fieldset', Fieldset)
app.component('Accordion', Accordion)
app.component('AccordionContent', AccordionContent)
app.component('AccordionHeader', AccordionHeader)
app.component('AccordionPanel', AccordionPanel)
app.component('Stepper', Stepper)
app.component('StepList', StepList)
app.component('StepPanels', StepPanels)
app.component('StepPanel', StepPanel)
app.component('StepItem', StepItem)
app.component('Step', Step)
app.component('RadioButton', RadioButton)
app.component('MultiSelect', MultiSelect)
app.component('Slider', Slider)
app.component('ToggleSwitch',ToggleSwitch)

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      prefix: 'p',
      darkModeSelector: 'none',
      cssLayer: false
    }
  }
})
app.use(router)
app.mount('#app')
