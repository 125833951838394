<script setup lang="ts">

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: false
  },
  outlined: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  severity: {
    type: String,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  }
})

const emit = defineEmits<{
  (e: 'click'): void
}>()
</script>

<template>
  <Button
    :label="title"
    :icon="`fas fa-${icon}`"
    :class="[
      'p-button',
      outlined ? 'p-button-outlined' : '',
      severity == 'danger' ? 'p-button-danger' : '',
      rounded ? 'p-button-rounded' : ''
    ]"
    @click="emit('click')"
    :disabled="disabled"
  >
  </Button>
</template>
<style scoped>
.p-button {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 16px;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
}
</style>
