<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue';
import Card from 'primevue/card';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import ApiService from '@/api/ApiService';
import type { ReportRequestByNxtKey } from '@/api/models/reports/ReportRequestByNxtKey';
import type { ToastMessageOptions } from 'primevue/toast';
import ConversionUtility from '@/utils/ConversionUtility';
import * as XLSX from 'xlsx'
import ProgressBar from 'primevue/progressbar';
import type { FDReportDetail } from '@/api/models/reports/FDReportDetail';

const loadingProgress = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const nextPageKey = ref(null)
const fdReportDetail = ref<FDReportDetail[]>([])

function getFDReportDetail(nextPageKey:any){
    isLoading.value = true
    const requestParam: ReportRequestByNxtKey ={
        nextPageKey: nextPageKey
    }

    ApiService.FDReport(requestParam)
    .then((response) => {
        if(response.statusCode == 200){
          fdReportDetail.value.push(...response.data.fdReportDetails)
          if (response.data.nextPageKey != null){           
            getFDReportDetail(response.data.nextPageKey)
          }
          if(response.data.nextPageKey == null){
            downloadReport()
          }
        }
        else{
            showToast('Error','No FD Detail', 'error')
        }
    })
    .catch((error) => {
        showToast('Errorerr', error, 'error')
    })
    .finally()
}
function downloadReport(){
  loadingProgress.value = 25;
    isLoading.value = true
    const reportDataDownload = [
  ["Fixed Deposit Details Report"],
  ["Total FD Accounts", fdReportDetail.value.length], 
  [],
  ["FD ID", "Customer ID", "Principle Amount", "Rate of Interest", "Total Interest", "Maturity Date", "Nominee Customer ID" , "Created On","Status"], 
];

fdReportDetail.value?.forEach(account => {
  reportDataDownload.push([
    account.fdId, 
    account.customerId, 
    ConversionUtility.toStringRupeesConversion(account.principleAmount), 
    account.rateOfInterest + '%', 
    ConversionUtility.toStringRupeesConversion(account.totalInterestEarned), 
    ConversionUtility.toDateFormat(account.maturityAt), 
    account.nomineeCustomerId, 
    ConversionUtility.toDateFormat(account.createdAt),
    account.isClosed ? 'Closed' : ''
  ]);
});

const worksheet = XLSX.utils.aoa_to_sheet(reportDataDownload);
worksheet['!cols'] = [
  { wch: 10 },
  { wch: 10 },
  { wch: 20 },
  { wch: 15 },
  { wch: 20 },
  { wch: 25 },
  { wch: 15 },
  { wch: 25 },
  { wch: 10 }
];
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' });

const url = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', `FD_Detail_Report as on${ConversionUtility.toDateFormat(Date.now()!!)}.xlsx`);
document.body.appendChild(link);
link.click();
isLoading.value= false
fdReportDetail.value = []
showToast('Success', 'Report has been downloaded', 'success')
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <Toast class="error-p-toast" />
    <main class="main">
        <Card class="search-card">
            <template #title>
                <h2 class="title-right text-3xl font-bold">Fixed Deposit Report</h2>
            </template>
            <template #content>
                <div v-if="fdReportDetail" style="display: flex; justify-content: flex-start; margin-top: 20px;gap: 1rem">
                    <CustomButton title="Download Report" @click="getFDReportDetail(nextPageKey)"/>
                </div>

                <Dialog
              :visible="isLoading"
              modal
              :draggable="false"
              :closable="false"
              style="width: 60vw"
              header="Downloading....."
            >
            <ProgressBar mode="indeterminate" style="height: 6px"></ProgressBar>
            </Dialog>
            </template>
        </Card>
    </main>
</template>
<style>
</style>