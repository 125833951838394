<script setup lang="ts">
import { RouterView } from 'vue-router'
import './assets/theme.css'
import InvalidScreenSize from './components/CustomComponent/InvalidScreenSize.vue';
import { onMounted, onUnmounted, ref } from 'vue'
import router from '@/router'
import Storage from '@/utils/Storage'
import { DynatraceManager } from '@/utils/DynatraceManager'
import * as pack from '../package.json'

const idleTime = ref(0);
const logoutTimeLimit = 5 * 60 * 1000;
let logoutTimer: number | undefined = undefined; 
const isMobile = ref<boolean>()

const resetTimer = () => {
  idleTime.value = 0;
  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
  startInactivityTimer();
};


onMounted(() => {
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keydown', resetTimer);
  window.addEventListener('click', resetTimer);
  window.addEventListener('scroll', resetTimer);
  startInactivityTimer();
});

onUnmounted(() => {
  window.removeEventListener('mousemove', resetTimer);
  window.removeEventListener('keydown', resetTimer);
  window.removeEventListener('click', resetTimer);
  window.removeEventListener('scroll', resetTimer);

  if (logoutTimer) {
    clearTimeout(logoutTimer);
  }
});


const startInactivityTimer = () => {
  logoutTimer = setTimeout(() => {
    Storage.clearData()
    router.push({
      name: 'login'
    })
  }, logoutTimeLimit);
};

</script>

<template>
  <div class="valid-screen-size"><RouterView /></div>
  <div class="invalid-screen-size"><InvalidScreenSize/></div>
</template>

<style>
#app {
  font-family: Epilogue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.valid-screen-size{
  display: block;
  height: 100vh;
}
.invalid-screen-size{
  display: none;
}
@media only screen and (max-width: 1300px) {
  .invalid-screen-size{
    display: block;
  }
  .valid-screen-size{
    display: none;
  }
}
</style>
