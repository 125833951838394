<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { SearchPigmyRequest } from '@/api/models/pigmy/SearchPigmyRequest'
import type { Pigmy } from '@/api/models/pigmy/Pigmy'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import ConversionUtility from '@/utils/ConversionUtility'
import { useConfirm } from 'primevue/useconfirm'
import type { PigmyStatementRequest } from '@/api/models/pigmy/PigmyStatementRequest'
import ValidationUtility from '@/utils/ValidationUtility'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import type { PigmyTransaction } from '@/api/models/pigmy/PigmyTransaction'
import type { FirstTransRequest } from '@/api/models/pigmy/FirstTransRequest'
import { InterestAndTotalCalculator } from '@/utils/InterestAndTotalCalculator'
import DateUtils from '@/utils/DateUtils'
import type { ClosePigmyRequest } from '@/api/models/pigmy/ClosePigmyRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import type PartialWithdrawalRequest from '@/api/models/pigmy/PartialWithdrawRequest'

const toast = useToast()
const confirmDialog = useConfirm()
const isLoading = ref<boolean>(false)
const customer = ref<CustomerDetailsData>()
const pigmy = ref<Pigmy>()
const searchQuery = ref<string>('')
const transactions = ref<PigmyTransaction[] | undefined>(undefined)
const firstTrans = ref()
const closePigmyConfirm = ref<boolean>()
const pigmyInterestRate = ref()
const interestAmount = ref()
const transferVoucherNo = ref<number>()
const totalamt = ref()
const isClosed = ref<boolean>(false)
const penaltyCharges = ref()
const lessThanOneYear = ref<boolean>(false)
const showWithdraw = ref(false)
const withdrawAmount =  ref<number>()
const voucherNo =  ref<number>()
const pigmyStatementColumns = [
  { field: 'pigmyId', header: 'ID', colStyle: { width: '15%' } },
  { field: 'receiptNo', header: 'Receipt Number', colStyle: { width: '15%' } },   
  { field: 'createdAt', header: 'Date', colStyle: { width: '15%' } },
  { field: 'openingBalance', header: 'Opening Balance(₹)', colStyle: { width: '15%' } },
  { field: 'deposit', header: 'Deposit(₹)', colStyle: { width: '14%' } },
  { field: 'withdraw', header: 'Withdraw(₹)', colStyle: { width: '14%' } },
  { field: 'closingBalance', header: 'Closing Amount(₹)', colStyle: { width: '15%' } }
]

function handelSearchPigmy(pigmyID: string) {
  if (!ValidationUtility.validateNumber(pigmyID)) {
    pigmy.value = undefined
    showToast('Invalid Pigmy ID!', 'Please provide a valid pigmy ID!', 'error')
    return
  }
  if (pigmyID.length < 7) {
    pigmy.value = undefined
    showToast('Invalid Input!!', 'Id needs to be seven digits!', 'error')
    return
  }
  isLoading.value = true
  searchQuery.value = pigmyID
  transactions.value = undefined
  withdrawAmount.value = undefined
  voucherNo.value = undefined
  showWithdraw.value = false
  pigmy.value = undefined
  const searchPigmyRequest: SearchPigmyRequest = {
    pigmyId: pigmyID
  }

  ApiService.searchPigmy(searchPigmyRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        pigmy.value = response.data
        isClosed.value = pigmy.value.isClosed? true : false
        const searchCustomer: CustomerDetailsRequest = {
          cusId: String(pigmy.value?.cusId)
        }
        ApiService.getCustomerDetails(searchCustomer)
          .then((response) => {
            setTimeout(() => {
              if (response.statusCode == 401) {
                Storage.clearData()
                router.push({
                  name: 'login'
                })
              }
            }, 2000)
            if (response.statusCode == 200) {
              customer.value = response.data
            }
          })
          .catch((error) => {
            showToast('Failed to Customer Details', error, 'error')
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        showToast('Failed to fetch Pigmy', response.error ?? 'Invalid pigmy ID', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Pigmy', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}
function showPigmyStatement() {
  isLoading.value = true
  const PigmyStatementRequest: PigmyStatementRequest = {
    pigmyId: pigmy.value?.id.toString()!!
  }

  ApiService.getPigmyStatement(PigmyStatementRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200 && response.data) {
        transactions.value = response.data
        if (!(transactions.value?.length > 0)) {
          showToast(`The pigmy account has no transactions`, 'No data found', 'info')
        }
      } else {
        showToast(
          'Failed to fetch SB account statements',
          response.error ?? 'Something went wrong',
          'error'
        )
      }
    })
    .catch((error) => {
      showToast('Failed to fetch SB account statements', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      showWithdraw.value = false
      withdrawAmount.value = undefined
      voucherNo.value = undefined
    })
}

function handleDataTableProps(index: number, data: any): string {
  switch (index) {
    case 0: {
      return data.pigmyId ?? '-'
    }
    case 1: {
      return data.receiptNo
    }
    case 2: {
      return ConversionUtility.toDateFormat(data.createdAt)
    }
    case 3: {
      return ConversionUtility.toStringRupeesConversion(data.openingBalance)
    }
    case 4: {
      return ConversionUtility.toStringRupeesConversion(data.deposit)
    }
    case 5: {
      return ConversionUtility.toStringRupeesConversion(data.withdraw)
    }
    case 6: {
      return ConversionUtility.toStringRupeesConversion(data.closingBalance)
    }
    default: {
      return 'NA'
    }
  }
}

function closePigmyValidate() {
  if (pigmy.value?.balance === 0) {
    showToast('Error', 'Pigmy balance is zero. Cannot close the account.', 'error')
    return
  }
  penaltyCharges.value = undefined
  transferVoucherNo.value = undefined

  isLoading.value = true
  const GetFirstTrans: FirstTransRequest ={
    pigmyID: pigmy.value?.id.toString()!!
  }

  ApiService.getFirstTrans(GetFirstTrans)
    .then(response => {
      if (response.statusCode == 200){
        firstTrans.value = response.data
        if(DateUtils.getYearsDifference(firstTrans.value, new Date().getTime()) < 1){
          lessThanOneYear.value = true
        }
        if(!lessThanOneYear.value){
          ApiService.getAllSettings()
            .then(response => {
              if (response.statusCode == 200) {
                pigmyInterestRate.value = response.data.pigmyInterestRate
                interestAmount.value = InterestAndTotalCalculator.calculatePigmyInterest(
                  pigmy.value?.balance!!,
                  pigmyInterestRate.value,
                  firstTrans.value,
                  new Date().getTime()
                )
                totalamt.value = pigmy.value?.balance!! + interestAmount.value
              }
            })
            .catch(error => {
              showToast('Error', 'Failed to fetch interest rate.'+error, 'error')
            })
            .finally(() => {
              isLoading.value = false
            })
        }
        closePigmyConfirm.value = true
      }
      else{
        showToast('Error', 'First pigmy transaction not found.', 'error')
      }
    })
    .catch(error => {
      showToast('Error', 'Failed to fetch first transaction.'+error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      
    })
}

function ClosePigmyConfirm() {
  if (!transferVoucherNo.value) {
    showToast('Error', 'Voucher number cannot be empty.', 'error')
    return
  }

  if (transferVoucherNo.value.toString().length > 8) {
    showToast('Error', 'Voucher number must be less than 8 digits.', 'error')
    return
  }

  if(lessThanOneYear.value && !penaltyCharges.value) {
    showToast('Error', 'Penalty charges cannot be empty.', 'error')
    return
  }

  if(ConversionUtility.toPaiseConversion(penaltyCharges.value) > pigmy.value?.balance!){
    showToast('Error', 'Penalty charges must less than balance.', 'error')
    return
  }

  if(lessThanOneYear.value){
    penaltyCharges.value = ConversionUtility.toPaiseConversion(penaltyCharges.value)
    totalamt.value = pigmy.value?.balance! - penaltyCharges.value
  }
  confirmTotalAmount()
}

function confirmTotalAmount() {
  closePigmyConfirm.value = false
  const confirmMessage = lessThanOneYear.value
    ? `Rs. ${ConversionUtility.toStringRupeesConversion(totalamt.value)} will be credited to your savings bank account, including penalty charges of Rs. ${ConversionUtility.toStringRupeesConversion(penaltyCharges.value)}.`
    : `Rs. ${ConversionUtility.toStringRupeesConversion(totalamt.value)} will be credited to your savings bank account, including an interest amount of Rs. ${ConversionUtility.toStringRupeesConversion(interestAmount.value)} at the rate of ${pigmyInterestRate.value}%.`

  confirmDialog.require({
    message: confirmMessage,
    header: 'Confirmation to Withdraw',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Withdraw'
    },
    accept() {
      closePigmyFinal()
    },
    reject() {}
  })
}

function closePigmyFinal() {

  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }

  const finalClosePigmy:ClosePigmyRequest = {
    pigmyId: pigmy.value?.id!!,
    interestAmount: interestAmount.value != null ? parseFloat(interestAmount.value.toFixed(2)) : null,
    requestAt: Date.now(),
    transferVoucherNo: Number(transferVoucherNo.value),
    penaltyAmount: penaltyCharges.value ?? null,
    requestBy: createBy
  }

  ApiService.ClosePigmy(finalClosePigmy)
    .then(response => {
      if (response.statusCode == 200) {
        showToast('Success', 'Pigmy account closed successfully.', 'success')
      }
    })
    .catch(error => {
      showToast('Error', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      handelSearchPigmy(pigmy.value?.id.toString()!!)
    })
   
}

function withdraw(){
  if(ConversionUtility.toPaiseConversion(withdrawAmount.value!) > (pigmy.value?.balance ?? 0) * 0.8){
    showToast('Invalid amount entered', 'Enter amount below the 80% of balance', 'error')
    return
  }
  if(!withdrawAmount.value){
    showToast('Withdraw amount cannot be blank', 'Enter amount', 'error')
    return
  }

  if(!voucherNo.value){
    showToast('Voucher no cannot be blank', 'Enter voucher no', 'error')
    return
  }

  if(voucherNo.value.toString().length > 8){
    showToast('Voucher no should be 8 digits', '', 'error')
    return
  }

  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }

  const request: PartialWithdrawalRequest ={
    id: pigmy.value?.id.toString()!,
    withdrawAmount: ConversionUtility.toPaiseConversion(withdrawAmount.value),
    voucherNumber: Number(voucherNo.value),
    createdAt: new Date().getTime(),
    createdBy: createBy
  }

  ApiService.partialWithdraw(request)
  .then((response) => {
    if(response.statusCode == 200){
      showToast('Withdraw successsfull', 'Amount has been transferred to SB Account', 'success')
    }
    else{
      showToast('Failed', 'Something is wrong', 'error')

    }
  })
  .catch((error) => {
    showToast('Error', error, 'error')
  })
  .finally(() => {
    isLoading.value = false
    handelSearchPigmy(pigmy.value?.id.toString()!!)
    withdrawAmount.value = undefined
    voucherNo.value = undefined
    showWithdraw.value = false
  })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <Toast class="error-p-toast" />
        <SearchComponent
          title="Search Pigmy"
          subtitle="Search for Pigmy using Pigmy ID. (Eg. - 1000001)."
          placeholder="Enter pigmy ID"
          @search-click="handelSearchPigmy"
        />
        <LoadingComponent
          text="Please wait while we process the request!"
          :dialogVisiblity="isLoading"
        />
        <div v-if="!pigmy && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No pigmy found for the provided search query!" style="font-weight: 600" />
        </div>
        <div v-if="pigmy != undefined && !isLoading" class="cont">
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer Id: </span>
              <span class="text">{{ pigmy?.cusId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text">{{ customer?.customer?.firstName }} {{ customer?.customer?.middleName ?? '' }} {{ customer?.customer?.lastName }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Pigmy Amount: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(pigmy?.pigmyAmount!!) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Balance: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(pigmy?.balance!!) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(pigmy?.createdAt!!) }}<br
              /></span>
            </div>
            <div v-if="isClosed">
              <span class="text-heading">Status: </span>
              <span class="text"
                ><Tag severity="danger">Closed</Tag><br
              /></span>
            </div>
            <div v-else>
              <span class="text-heading">Status: </span>
              <span class="text"
                ><Tag severity="success">Open</Tag><br
              /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <CustomButton icon="book" title="View pigmy Statement" @click="showPigmyStatement" />
              <CustomButton
            v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2'|| Storage.getEmpAccessRole() == null)"
              icon="minus"
              severity="warning"
              title="Partial withdraw"
              @click="{showWithdraw = true; transactions = undefined}"
              :disabled="isClosed"
            />
            </div>
            <CustomButton
            v-if="!(Storage.getEmpAccessRole() == 'Operator_L3' || Storage.getEmpAccessRole() == 'Operator_L2'|| Storage.getEmpAccessRole() == null)"
              icon="ban"
              severity="danger"
              title="Close pigmy"
              @click="closePigmyValidate"
              :disabled="isClosed"
            />

          </div>
        </div>
        <div class="" v-if="showWithdraw">
          <Tag severity="secondary">Only 80% of the Balance can be withdrawn.</Tag>
          <div class="horizontal-alignment-with-border">
            <InputNumber
            class="amount-input"
            v-model="withdrawAmount"
            locale="en-IN"
            :minFractionDigits="0"
            fluid
            placeholder="Amount"
            :min="0"
          />
          <InputNumber
            class="amount-input"
            v-model="voucherNo"
            :useGrouping="false"
            placeholder="Voucher Number"
            :min="1"
          />
          <CustomButton
            class="button"
            title="withdraw "
            icon="indian-rupee"
            @click="withdraw()"
          />
          <CustomButton
            class="button"
            :outlined="true"
            title="Close"
            icon="times"
            @click="showWithdraw = false"
          />
          </div>
        </div>
        <div
          class="view-statement-container"
          v-if="transactions && transactions.length != 0 && !isLoading"
        >
          <DataTable :value="transactions" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
            <Column
              v-for="(col, index) in pigmyStatementColumns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
              :style="col.colStyle"
            >
              <template #body="slotProps">
                {{ handleDataTableProps(index, slotProps.data) }}
              </template>
            </Column>
            <Column header="Status" style="width: 15%">
              <template #body="slotProps">
                <Tag severity="success" v-if="slotProps.data.status == 'APPROVED'">Approved</Tag>
                <Tag severity="warning" v-if="slotProps.data.status == null">Approved</Tag>
                <Tag severity="danger" v-if="slotProps.data.status == 'PENDING'">Pending</Tag>
                <Tag severity="secondary" v-if="slotProps.data.status == 'NEW'">New</Tag>
              </template>
            </Column>
          </DataTable>
        </div>
        <Dialog 
        :visible="closePigmyConfirm" 
        modal
        :draggable="false"
        :closable="false"
        :style="{ width: '17rem' }"
        header="Enter Details"
        >
          <div v-if="lessThanOneYear" class="">
            <Tag severity="danger">Enter penalty charges.</Tag>
          </div>
          <div class="dialog-form">
            <FloatLabel>
              <InputNumber
              id="pigmyVoucher"
              v-model="transferVoucherNo"
              :min="1"
              class="input-text"
              :useGrouping="false"
            />
            <label for="pigmyVoucher" class="mandatory">Voucher Number</label>
          </FloatLabel><br>
          <FloatLabel v-if="lessThanOneYear">
            <InputNumber
              id="penaltyCharges"
              input-id="locale-indian"
              locale="en-IN"
              mode="currency"
              currency="INR"
              v-model="penaltyCharges"
              :maxFractionDigits="0"
              class="input-text"
              size="large"
              :min="0"
            />
            <label for="pigmyVoucher" class="mandatory">Penalty Charges</label>
          </FloatLabel>
          </div>
          <template #footer>
            <CustomButton
            title="Cancel"
            :outlined="true"
            @click="closePigmyConfirm = false"
          ></CustomButton>
            <CustomButton
            title="Submit"
            @click="ClosePigmyConfirm"
          ></CustomButton>
          </template>
        </Dialog>
        <ConfirmDialog :draggable="false" :closable="false" />
      </template>
    </Card>
  </main>
</template>
<style scoped>
.dialog-form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 2rem;
}
.mandatory::after {
  content: ' *';
  color: red;
}

.center-tag {
  display: flex;
  justify-content: center;
}
.horizontal-alignment-with-border {
  margin: 16px 0;
  background-color: white;
  padding: 1.5rem 1.5rem;
  border-radius: 18px;
  border: solid rgba(0, 0, 0, 0.4) 1px;
  display: flex;
  gap: 16px;
  align-items: center;

  .amount-input {
    height: 40px;
    width: 40%;
  }

  .button {
    height: 40px;
  }
}
</style>
