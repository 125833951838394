import type { Customer } from '@/api/models/customer/Customer'
import type { SelectCustomer } from '@/api/models/customer/SelectCustomer'
import type { CustomerDataTable } from '@/models/CustomerDataTable'
import ConversionUtility from './ConversionUtility'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'


export class CustomerMapper {
  static mapToTableData(customers: Customer[]): CustomerDataTable[] {
    return customers.map((customer) => ({
      customerId: customer.customerId,
      customerName: `${customer.firstName} ${customer.middleName ? customer.middleName + ' ' : ''}${customer.lastName}`,
      phoneNumber: customer.phoneNumber,
      aadharNumber: customer.aadharNumber,
      panNumber: customer.panNumber,
      address: `${customer.houseNumber}, ${customer.addressLine1}${customer.addressLine2 ? ', ' + customer.addressLine2 : ''}, ${customer.city}, ${customer.district}`,
      pincode: customer.pincode
    }))
  }
  static mapToSelectCustomer(customers: Customer | null): SelectCustomer {
    if (customers) {
      return {
        customerId: customers.customerId,
        aadharNumber: customers.aadharNumber,
        address: `${customers.houseNumber}, ${customers.addressLine1}${customers.addressLine2 ? ', ' + customers.addressLine2 : ''}, ${customers.city}, ${customers.district}`,
        city: customers.city,
        createdAt: customers.createdAt,
        dateOfBirth: ConversionUtility.toDateFormat(customers.dateOfBirth),
        district: customers.district,
        drivingLicence: customers.drivingLicence,
        email: customers.email,
        name: `${customers.firstName} ${customers.middleName ? customers.middleName + '' : ''} ${customers.lastName}`,
        gender: customers.gender,
        houseNumber: customers.houseNumber,
        panNumber: customers.panNumber,
        phoneNumber: customers.phoneNumber,
        pincode: customers.pincode,
        taluk: customers.taluk,
        updatedAt: ConversionUtility.toDateFormat(customers.updatedAt)
      }
    } else {
      throw new Error('No customer provided')
    }
  }
}
