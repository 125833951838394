<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type { ApproveLoanRequest } from '@/api/models/loan/ApproveLoanRequest'
import type { Loan } from '@/api/models/loan/Loan'
import type { LoanDisburseRequest } from '@/api/models/loan/LoanDisburseRequest'
import type { RejectLoanRequest } from '@/api/models/loan/RejectLoanRequest'
import LoadingComponent from '@/components/LoadingComponent.vue'
import router from '@/router'
import ConversionUtility from '@/utils/ConversionUtility'
import Storage from '@/utils/Storage'
import type { ToastMessageOptions } from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { onMounted, ref } from 'vue'
import LoanComponent from '@/components/CustomComponent/LoanComponent.vue'

const toast = useToast()
const isLoading = ref<boolean>(false)
const confirmDialog = useConfirm()

const options = ref<string[]>(['Pending', 'Approved'])
const selectedOption = ref<string>('Pending')
const displayLoans = ref<Loan[]>([])
const selectedLoan = ref<Loan>()
const voucherNo = ref<number>()

onMounted(() => {
  getPendingLoans()
})

function handelLoanAction(loan:Loan,loanAction:string,voucher:number){
  selectedLoan.value = loan
  voucherNo.value = voucher
  if (loanAction=='approveLoan'){
    handelApproveLoan()
  }
  if (loanAction=='rejectLoan'){
    handelRejectLoan()
  }
  if (loanAction=='disburseLoan'){
    handelDisburseLoan()
  }
}

function getPendingLoans() {
  isLoading.value = true
  ApiService.getPendingLoans()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        displayLoans.value = response.data
      } else {
        displayLoans.value = []
        showToast('Failed to fetch loans', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      displayLoans.value = []
      showToast('Failed to fetch loans', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function getApprovedLoans() {
  isLoading.value = true
  ApiService.getApprovedLoans()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        displayLoans.value = response.data
      } else {
        displayLoans.value = []
        showToast('Failed to fetch loans', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      displayLoans.value = []
      showToast('Failed to fetch loans', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelSelectChange() {
  if (selectedOption.value == 'Approved') {
    getApprovedLoans()
  } else {
    getPendingLoans()
  }
}

function handelApproveLoan() {
  confirmDialog.require({
    message: `Are you sure you want to approve loan account ${selectedLoan.value?.applicationId}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      isLoading.value = true
      const request: ApproveLoanRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        approvedDate: new Date().getTime().toString()
      }
      ApiService.approveLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('Loan approved successfully', 'Loan approved successfully', 'success')
            getPendingLoans()
          } else {
            showToast('Failed to approve loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to approve loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
        })
    },
    reject() {}
  })
}

function handelRejectLoan() {
  confirmDialog.require({
    message: `Are you sure you want to reject loan account ${selectedLoan.value?.applicationId}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      isLoading.value = true
      const request: RejectLoanRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        rejectionDate: new Date().getTime().toString()
      }
      ApiService.rejectLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('Loan rejection successfully', 'Loan rejected successfully', 'success')
            getPendingLoans()
          } else {
            showToast('Failed to reject loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to reject loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
        })
    },
    reject() {}
  })
}

function handelDisburseLoan() {
  if (!voucherNo.value) {
    showToast('Please enter voucher number', 'Please enter voucher number', 'error')
    return
  }
  if (voucherNo.value.toString().length > 8) {
    showToast('Voucher number should be 8 digits', 'Please enter 8 digit voucher number', 'error')
    return
  }
  confirmDialog.require({
    message: `Are you sure you want to disburse loan account of ${ConversionUtility.toStringRupeesConversion(
      selectedLoan.value?.amountToTransferToSB!!
    )}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      if (!voucherNo.value) {
        showToast('Please enter voucher number', 'Please enter voucher number', 'error')
        return
      }
      if (voucherNo.value.toString().length > 8) {
        showToast(
          'Please enter a valid voucher number',
          'Please enter a valid voucher number',
          'error'
        )
        return
      }
      isLoading.value = true
      const request: LoanDisburseRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        disbursalDate: new Date().getTime(),
        passbookVoucherNo: voucherNo.value,
        shareholderVoucherNo: voucherNo.value
      }
      ApiService.disburseLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast(`Loan Disbursement successfully for loan account ${response.data}`, 'Loan disbursement successfully', 'success')
            if(selectedOption.value=='Pending') {
              getPendingLoans()
            }
            else{
              getApprovedLoans()
            }
          } else {
            showToast('Failed to disburse loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to disburse loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          voucherNo.value = undefined
        })
    },
    reject() {
      voucherNo.value = undefined
    }
  })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <ConfirmDialog :draggable="false" :closable="false" />
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <Panel header="Approve Loans">
          <div class="loan-approve-container">
            <Toast class="error-p-toast" />
            <SelectButton
              :allow-empty="false"
              v-model="selectedOption"
              :options="options"
              v-on:change="handelSelectChange"
            />
            <Divider style="margin: 0" />
            <div v-if="displayLoans.length > 0 && !isLoading">
              <LoanComponent v-for="loan in displayLoans" :loan="loan" :key="loan.applicationId" @submit="handelLoanAction"/>
            </div>
            <Tag v-else severity="secondary">No Transactions Found</Tag>
          </div>
        </Panel>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.loan-approve-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
