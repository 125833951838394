import { jsPDF } from 'jspdf';
import type { ApplyLoanRequest } from '@/api/models/loan/ApplyLoanRequest'
import ConversionUtility from '@/utils/ConversionUtility'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'

export class PDFGenerator {

  public static loanApplication(isCustomerCopy: boolean, applyLoanRequest: ApplyLoanRequest, customerDetails: CustomerDetailsData) {
      const doc = new jsPDF();

      // Add "Customer Copy" heading in Times New Roman, bold, 16 font size, centered
      doc.setFont('times', 'bold');
      doc.setFontSize(16);
      if (isCustomerCopy) {
        doc.text('Loan Application [Customer Copy]', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
      } else {
        doc.text('Loan Application [Bank Copy]', doc.internal.pageSize.getWidth() / 2, 10, { align: 'center' });
      }
      // Add bank heading in Times New Roman, bold, 18 font size, centered
      doc.setFont('times', 'bold');
      doc.setFontSize(18);
      doc.text('Bhavsar Patini Sahakar Sanga(R)', doc.internal.pageSize.getWidth() / 2, 20, { align: 'center' });

      // Add location in Arial, normal, 14 font size, centered with reduced gap
      doc.setFont('arial', 'normal');
      doc.setFontSize(14);
      doc.text('Badami, Tq:Badami, Dist:Bagalkot', doc.internal.pageSize.getWidth() / 2, 26, { align: 'center' });

      // Add registration number below the email
      doc.setFontSize(14);
      doc.text('RegNo: 56069/2024-25', doc.internal.pageSize.getWidth() / 2, 38, { align: 'center' });

      // Add email ID below location
      doc.setFontSize(14);
      doc.text('Email: bhavasarbankbadami@gmail.com', doc.internal.pageSize.getWidth() / 2, 32, { align: 'center' });


      // Add gap before the dotted line
      const lineY = 44; // y-coordinate for the line
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0); // Set line color to black

      // Draw a horizontal dotted line
      doc.setLineDashPattern([2, 2], 0); // Dotted line pattern
      doc.line(20, lineY, doc.internal.pageSize.getWidth() - 20, lineY); // Draw line

      // Add loan application ID on the left side
      doc.setFontSize(14);
      doc.setFont('times', 'normal'); // Reset font to normal for the label
      doc.text('Application ID:', 20, lineY + 6); // Adjust y-coordinate as needed
      doc.setFont('times', 'bold'); // Set font to bold for ID value
      doc.text(applyLoanRequest.applicationId.toString(), 55, lineY + 6); // ID value with a space for better alignment

      // Add application date on the right side
      doc.setFont('arial', 'normal'); // Reset font to normal for the application date
      doc.text(`Date: ${ConversionUtility.toDateFormat(applyLoanRequest.createdAt)}`, doc.internal.pageSize.getWidth() - 20, lineY + 6, { align: 'right' });

      // Draw another dotted line before customer details
      const customerLineY = lineY + 12; // y-coordinate for the customer details line
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0); // Set line color to black
      doc.setLineDashPattern([2, 2], 0); // Dotted line pattern
      doc.line(20, customerLineY, doc.internal.pageSize.getWidth() - 20, customerLineY); // Draw line

      // Add header for Customer Details
      doc.setFont('times', 'bold');
      doc.setFontSize(14);
      const headerY = customerLineY + 12;
      doc.text('Customer Details', doc.internal.pageSize.getWidth() / 2, customerLineY + 12, { align: 'center' });

      // Draw an underline for the header
      const headerTextWidth = doc.getTextWidth('Customer Details');
      doc.line((doc.internal.pageSize.getWidth() / 2) - (headerTextWidth / 2), headerY + 2, (doc.internal.pageSize.getWidth() / 2) + (headerTextWidth / 2), headerY + 2);

      // Add Customer ID on the left side and Customer Name on the right side below the header
      const customerDetailsY = customerLineY + 20; // Y position for customer details
      doc.setFont('times', 'normal'); // Reset font to normal for the label
      doc.text(`ID: ${applyLoanRequest.customerId}`, 20, customerDetailsY); // Customer ID
      doc.text(`Name: ${customerDetails.customer?.firstName} ${customerDetails.customer?.lastName}`, doc.internal.pageSize.getWidth() - 20, customerDetailsY, { align: 'right' }); // Customer Name

// Add PAN and Phone Number below customer details
      const panPhoneY = customerDetailsY + 10; // Y position for PAN and Phone Number
      doc.text(`PAN: ${customerDetails.customer?.panNumber}`, 20, panPhoneY); // PAN
      doc.text(`Phone Number: ${customerDetails.customer?.phoneNumber}`, doc.internal.pageSize.getWidth() - 20, panPhoneY, { align: 'right' }); // Phone Number

      // Draw a dashed line after customer details
      const dividerY = panPhoneY + 10; // y-coordinate for the divider line
      doc.setLineWidth(0.5);
      doc.setDrawColor(0, 0, 0); // Set line color to black
      doc.setLineDashPattern([2, 2], 0); // Dotted line pattern
      doc.line(20, dividerY, doc.internal.pageSize.getWidth() - 20, dividerY); // Draw line

      // Add "Loan Details" heading below the dashed line
      doc.setFont('times', 'bold');
      doc.setFontSize(14);
      const loanDetailsY = dividerY + 12;
      doc.text('Loan Details', doc.internal.pageSize.getWidth() / 2, loanDetailsY, { align: 'center' });

      // Draw an underline for the Loan Details header
      const loanDetailsHeaderTextWidth = doc.getTextWidth('Loan Details');
      doc.line((doc.internal.pageSize.getWidth() / 2) - (loanDetailsHeaderTextWidth / 2), loanDetailsY + 2, (doc.internal.pageSize.getWidth() / 2) + (loanDetailsHeaderTextWidth / 2), loanDetailsY + 2);

      // Add Principal Amount on the left and corresponding amount on the right
      // Add principal amount and corresponding value
      doc.setFont('times', 'normal');
      doc.setFontSize(14);
      doc.text('Principal Amount (A)', 20, 130);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.loanAmount)}`, doc.internal.pageSize.getWidth() - 20, 130, { align: 'right' });

      // Add shareholder transfer amount
      doc.text(`Shareholder Transfer Amount @ ${applyLoanRequest.shareTransferPercentage}% (B)`, 20, 140);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.shareHolderTransferAmount)}`, doc.internal.pageSize.getWidth() - 20, 140, { align: 'right' });

// Add processing fees
      doc.text('Processing Fees (C)', 20, 150);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.processingFees)}`, doc.internal.pageSize.getWidth() - 20, 150, { align: 'right' });

      doc.text('Building Fees (D)', 20, 160);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.buildingFees)}`, doc.internal.pageSize.getWidth() - 20, 160, { align: 'right' });

      doc.text('Miscellaneous Fees (E)', 20, 170);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.miscFees)}`, doc.internal.pageSize.getWidth() - 20, 170, { align: 'right' });

      doc.text('Share Fees (F)', 20, 180);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.shareFees)}`, doc.internal.pageSize.getWidth() - 20, 180, { align: 'right' });

// Add a horizontal line after processing fees
      doc.line(20, 190, doc.internal.pageSize.getWidth() - 20, 190); // horizontal line

// Add transfer to SB
      doc.text('Transfer to SB (A - [B + C + D + E + F])', 20, 200);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.amountToTransferToSB)}`, doc.internal.pageSize.getWidth() - 20, 200, { align: 'right' });

      // Add total interest rate
      doc.text(`Total Interest Rate @ ${applyLoanRequest.interestRate}% (D)`, 20, 220);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.interestAmount)}`, doc.internal.pageSize.getWidth() - 20, 220, { align: 'right' });

// Add horizontal line after interest rate
      doc.line(20, 230, doc.internal.pageSize.getWidth() - 20, 230); // horizontal line

// Add total payable amount
      doc.text('Total Payable Amount (A + D)', 20, 240);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.loanAmount + applyLoanRequest.interestAmount)}`, doc.internal.pageSize.getWidth() - 20, 240, { align: 'right' });

      doc.line(20, 250, doc.internal.pageSize.getWidth() - 20, 250);

      // Add EMI amount below the horizontal line
      doc.setFont('times', 'bold'); // Set font to bold for EMI amount
      doc.setFontSize(16); // Increase font size for EMI amount
      doc.text('EMI', 20, 260);
      doc.text(`Rs. ${ConversionUtility.toRuppesCoversion(applyLoanRequest.emiAmount)}`, doc.internal.pageSize.getWidth() - 20, 260, { align: 'right' });

// Add a line below the EMI amount
      doc.line(20, 270, doc.internal.pageSize.getWidth() - 20, 270); // horizontal line below EMI amount

      // Add note about the receipt
      doc.setFont('times', 'normal');
      doc.setFontSize(12);
      doc.text('This is a computer-generated receipt; signature is not required.', doc.internal.pageSize.getWidth() / 2, 280, { align: 'center' });


      doc.setFont('times', 'normal');
      doc.setFontSize(10);
      doc.text('powered by Matrex Softwares', doc.internal.pageSize.getWidth() - 20, doc.internal.pageSize.getHeight() - 10, { align: 'right' });
      // Save the PDF

      // Convert PDF to Blob
      const pdfBlob = doc.output('blob');

     // Create a Blob URL and open it to trigger the print dialog
      const blobUrl = URL.createObjectURL(pdfBlob);

      const printWindow = window.open(blobUrl);
      if (printWindow) {
       printWindow.onload = function () {
          printWindow.focus();
        printWindow.print();
       };
    }
  }
}