import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MainView from '@/views/MainView.vue'
import AddCustomer from '@/views/CreateCustomer.vue'
import SearchCustomer from '@/views/SearchCustomer.vue'
import SearchAccount from '@/views/SearchAccount.vue'
import CreateAccount from '@/views/CreateAccount.vue'
import SettingsView from '@/views/SettingsView.vue'
import Storage from '@/utils/Storage'
import EmployeeView from '@/views/EmployeeView.vue'
import ApplyLoan from '@/views/ApplyLoan.vue'
import LoanStatus from '@/views/LoanStatus.vue'
import SearchLoan from '@/views/SearchLoan.vue'
import CreateFD from '@/views/CreateFD.vue'
import SearchFD from '@/views/SearchFD.vue'
import CreatePigmy from '@/views/CreatePigmy.vue'
import SearchPigmy from '@/views/SearchPigmy.vue'
import BranchesView from '@/views/BranchesView.vue'
import ShareholdersView from '@/views/ShareholdersView.vue'
import ApprovePigmy from '@/views/ApprovePigmy.vue'
import FDView from '@/views/FDView.vue'
import PigmyReports from '@/views/PigmyReports.vue'
import SavingsReports from '@/views/SavingsReports.vue'
import ApproveLoan from '@/views/ApproveLoan.vue'
import CustomerReport from '@/views/CustomerReport.vue'
import IncomeAndExpense from '@/views/IncomeAndExpenses.vue'
import ShareholderReport from '@/views/ShareholderReport.vue'
import FDReport from '@/views/FDReport.vue'
import LoanReport from '@/views/LoanReport.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'login',
      component: LoginView
    },
    {
      path: '/main',
      name: 'main',
      component: MainView,
      children: [
        {
          path: '',
          name: 'createCustomer',
          component: AddCustomer
        },
        {
          path: '',
          name: 'searchCustomer',
          component: SearchCustomer
        },
        {
          path: '',
          name: 'addAccount',
          component: CreateAccount
        },
        {
          path: '',
          name: 'searchAccount',
          component: SearchAccount
        },
        {
          path: '',
          name: 'applyLoan',
          component: ApplyLoan
        },
        {
          path: '',
          name: 'loanStatus',
          component: LoanStatus
        },
        {
          path: '',
          name: 'searchLoan',
          component: SearchLoan
        },
        {
          path: '',
          name: 'approveLoan',
          component: ApproveLoan
        },
        {
          path: '',
          name: 'createFD',
          component: CreateFD
        },
        {
          path: '',
          name: 'searchFD',
          component: SearchFD
        },
        {
          path: '',
          name: 'fdView',
          component: FDView
        },
        {
          path: '',
          name: 'createPigmy',
          component: CreatePigmy
        },
        {
          path: '',
          name: 'searchPigmy',
          component: SearchPigmy
        },
        {
          path: '',
          name: 'approvePigmy',
          component: ApprovePigmy
        },
        {
          path: '',
          name: 'settingsView',
          component: SettingsView
        },
        {
          path: '',
          name: 'employeeView',
          component: EmployeeView
        },
        {
          path: '',
          name: 'branchesView',
          component: BranchesView
        },
        {
          path: '',
          name: 'shareholderView',
          component: ShareholdersView
        },
        {
          path: '',
          name: 'pigmyReports',
          component: PigmyReports
        },
        {
          path: '',
          name: 'savingsReport',
          component: SavingsReports
        },
        {
          path: '',
          name: 'customerReport',
          component: CustomerReport
        },
        {
          path: '',
          name: 'incomeAndExpenses',
          component: IncomeAndExpense
        },
        {
          path: '',
          name: 'shareholderReport',
          component: ShareholderReport
        },
        {
          path: '',
          name: 'fdReport',
          component: FDReport
        },
        {
          path: '',
          name: 'loanReport',
          component: LoanReport
        }
      ]
    }
  ]
})
router.beforeEach(async (to, from) => {
  if (Storage.getToken() === '' && to.name !== 'login') {
    return { name: 'login' }
  }
})

export default router
