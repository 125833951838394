<script setup lang="ts">
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import { onMounted, ref } from 'vue'
import type { FDInterestRate } from '@/models/FDInterestRate'
import type { LoanInterestRate } from '@/models/LoanInterestRate'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import ValidationUtility from '@/utils/ValidationUtility'
import type { RdInterest } from '@/models/RdInterestRates'
import ApiService from '@/api/ApiService'
import type { AllSettingsDetails } from '@/api/models/settings/AllSettingsDetails'
import CryptoUtil from '@/utils/CryptoUtils'

const toast = useToast()
const confirmDialog = useConfirm()
const isLoading = ref(true)
const index = ref<number | undefined>()
const bankName = ref()
const bankRegNo = ref()
const sbInterestGeneral = ref()
const sbInterestSenior = ref()
const sbCalculationFrequencyOptions = ref<string[]>(['Monthly', 'Quarterly', 'Yearly'])
const defaultSbCalculationFrequency = ref<string>('Quarterly')
const id = ref()

//Pigmy Constants
const pigmyGeneralInterest = ref()

//FD Constants
const addFDInterestDetails = ref(false)
const startDuration = ref()
const endDuration = ref()
const startDurationType = ref()
const endDurationType = ref()
const generalInterestRateFD = ref()
const scInterestRateFD = ref()
const option1 = ref([{name: 'Days'},{name: 'Years'}])
const fdInterestRates = ref<FDInterestRate[]>([])

//RD Constants 
const addRdDetails = ref(false)
const RdInterestRates = ref<RdInterest[]>([])
const generalInterestRateRD = ref()
const scInterestRateRD = ref()
const RdDuration = ref()

//Loan Constants
const loanInterestRates = ref<LoanInterestRate[]>([])
const loanDetailsAdd = ref(false)
const loanType = ref()
const option2 = ref([
  { name: 'Personal' },
  { name: 'Home' },
  { name: 'Auto' },
  { name: 'Education' },
  { name: 'Business' },
  { name: 'Gold' },
  { name: 'Agriculture' },
  { name: 'Pigmy' },
  { name: 'Collateral' }
])
const generalInterestRateLoan = ref()
const seniorInterestRateLoan = ref()
const settings = ref<AllSettingsDetails>()

onMounted(() =>{
  ApiService.getAllSettings()
.then(
  (response) => {
    if(response.statusCode == 200){
      settings.value = response.data
      isLoading.value = false
      
      bankName.value = settings.value.bankName
      bankRegNo.value = settings.value.bankRegNo
      sbInterestGeneral.value = settings.value.sbInterestRateGeneral
      sbInterestSenior.value = settings.value.sbInterestRateSC
      defaultSbCalculationFrequency.value = settings.value.sbCalculationFrequency
      pigmyGeneralInterest.value = settings.value.pigmyInterestRate
      fdInterestRates.value = settings.value.fdInterestRates
      RdInterestRates.value = settings.value.recurringInterestRates
      loanInterestRates.value = settings.value.loanInterestRates
    }
    else if(response.statusCode == 404){
      showToast('No Setting yet', 'Please add new settings', 'warn')
  }
  }
)
.catch((error) => {
  showToast('Failed', JSON.parse(CryptoUtil.decrypt(error.response.data)).error, 'error')
})
.finally(()=>{
  isLoading.value = false
})
}
)


function handleAddFDDetails(){
  startDuration.value = undefined;
  endDuration.value = undefined;
  startDurationType.value = undefined;
  endDurationType.value = undefined;
  generalInterestRateFD.value = undefined;
  scInterestRateFD.value = undefined;
  addFDInterestDetails.value = true
}
function handleFDAddDetailsRequest(){


  if(!startDuration.value){
    showToast('Error in Start Date', 'Please enter Start date', 'error')
    return
  }
  if(!(ValidationUtility.validateNumber(startDuration.value))){
    showToast('Error in Start Date', 'Please enter only number', 'error')
    return
  }
  if(!endDuration.value){
    showToast('Error in Start Date', 'Please enter Start date', 'error')
    return
  }
  if(!(ValidationUtility.validateNumber(endDuration.value))){
    showToast('Error in End Date', 'Please enter only number', 'error')
    return
  }
  if(!startDurationType.value){
    showToast('Error', 'Please Select Start duration is Day or Year', 'error')
    return
  }

  if(startDurationType.value == endDurationType.value && Number(startDuration.value) >= Number(endDuration.value)){
    showToast('Error', 'Start Duration Should be lesser than End Duration', 'error') 
    return
  }

  if(startDurationType.value == 'Years' && endDurationType.value == "Days"){
    showToast('Error', 'Please select appropriate Start and End Duration', 'error')
    return
  }

  if(!endDurationType.value){
    showToast('Error', 'Please Select End duration is Day or Year', 'error')
    return
  }

  if(!generalInterestRateFD.value){
    showToast('Error', 'Please Enter General Interest Rate', 'error')
    return
  }

  if(!scInterestRateFD.value){
    showToast('Error', 'Please Enter Interest Rate for Senior Citizen', 'error')
    return
  }

  const newRates : FDInterestRate = {
    id: "FD_"+startDuration.value+"_"+endDuration.value ,
    startDuration: Number(startDuration.value),
    endDuration: Number(endDuration.value),
    startDurationType: startDurationType.value,
    endDurationType: endDurationType.value,
    generalInterestRate: Number(generalInterestRateFD.value),
    scInterestRate: Number(scInterestRateFD.value),
  }

  if (index.value !== undefined && index.value >= 0) {
    fdInterestRates.value[index.value].startDuration = Number(startDuration.value)
    fdInterestRates.value[index.value].endDuration = Number(endDuration.value)
    fdInterestRates.value[index.value].startDurationType = startDurationType.value
    fdInterestRates.value[index.value].endDurationType = endDurationType.value
    fdInterestRates.value[index.value].generalInterestRate = Number(generalInterestRateFD.value)
    fdInterestRates.value[index.value].scInterestRate = Number(scInterestRateFD.value)
    index.value = undefined; 
    showToast('Success', 'FD Interest Rates updated Successfully', 'success')
    addFDInterestDetails.value = false
  }
  else{
    if(fdInterestRates.value.push(newRates)){
      showToast('Success', 'FD Interest Rates added Successfully', 'success')
      addFDInterestDetails.value = false
    }
  }
}
function editFDItem(FDDat: FDInterestRate) {
  index.value = undefined
  addFDInterestDetails.value = true
  startDuration.value = FDDat.startDuration
  endDuration.value = FDDat.endDuration
  startDurationType.value = FDDat.startDurationType
  endDurationType.value = FDDat.endDurationType
  generalInterestRateFD.value = Number(FDDat.generalInterestRate)
  scInterestRateFD.value = Number(FDDat.scInterestRate)
  index.value = fdInterestRates.value.indexOf(FDDat);
}

function deleteFDItem(FDData: FDInterestRate){
  confirmDialog.require({
    message: `Are you sure you want to delete ${FDData.startDuration + " " +FDData.startDurationType}  to ${FDData.endDuration + " " + FDData.endDurationType} scheme?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      fdInterestRates.value.splice(fdInterestRates.value.indexOf(FDData), 1)
    },
    reject(){
    }
  }
  )
}
function clearAllFD(){
  confirmDialog.require({
    message: `Are you sure you want to delete All Fixed Deposit Interest Rates?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      fdInterestRates.value.splice(0, fdInterestRates.value.length)
    },
    reject(){
    }
  }
  )
}


function handleLoanAddDetails(){
  loanDetailsAdd.value = true
  loanType.value = '';
  generalInterestRateLoan.value = undefined
  seniorInterestRateLoan.value = undefined
}
function handleLoanAddDetailsRequest(){
  if(!loanType.value){
    showToast('Error', 'Please Select Loan Type', 'error')
    return
  }

  if(!generalInterestRateLoan.value){
    showToast('Error', 'Please Enter General Interest Rate', 'error')
    return
  }

  if(!seniorInterestRateLoan.value){
    showToast('Error', 'Please Enter Senior Citizen Interest Rate', 'error')
    return
  }

  const newLoanInterest : LoanInterestRate =   {
    id: "LOAN_"+loanType.value,
    loanType: loanType.value,
    generalInterestRates: Number(generalInterestRateLoan.value),
    scInterestRates: Number(seniorInterestRateLoan.value)
  }

  if (index.value !== undefined && index.value >= 0) {
    if(loanInterestRates.value.find(loan => loan.loanType === newLoanInterest.loanType)){
    showToast('Error', 'This Loan Type already exists', 'error');
    return;
    }
    loanInterestRates.value[index.value].loanType = loanType.value
    loanInterestRates.value[index.value].generalInterestRates = Number(generalInterestRateLoan.value)
    loanInterestRates.value[index.value].scInterestRates = Number(seniorInterestRateLoan.value)
    index.value = undefined;
    showToast('Success', 'Loan Interests updated Successfully', 'success')
    loanDetailsAdd.value = false
  }
  else{
    if(loanInterestRates.value.find(loan => loan.loanType === newLoanInterest.loanType)){
    showToast('Error', 'This Loan Type already exists', 'error');
    return;
  }
    if(loanInterestRates.value.push(newLoanInterest)){
    showToast('Success', 'Loan Interests Successfully added', 'success')
    loanDetailsAdd.value = false
  }
  }

}
function editLoanItem(LoanData: LoanInterestRate){
  index.value = undefined
  loanDetailsAdd.value = true
  loanType.value = LoanData.loanType
  generalInterestRateLoan.value = LoanData.generalInterestRates
  seniorInterestRateLoan.value = LoanData.scInterestRates
  index.value = loanInterestRates.value.indexOf(LoanData)
}
function deleteLoanItem(LoanData : LoanInterestRate){
  confirmDialog.require({
    message: `Are you sure you want to delete ${LoanData.loanType} Loan Scheme?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      loanInterestRates.value.splice(loanInterestRates.value.indexOf(LoanData), 1)
    },
    reject(){
    }
  }
  )
}
function clearAllLoan(){
  confirmDialog.require({
    message: `Are you sure you want to delete All Loan schemes?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      loanInterestRates.value = []
    },
    reject(){
    }
  }
  )
}

function handleAddRdDetails(){
  RdDuration.value = undefined,
  generalInterestRateRD.value = undefined,
  scInterestRateRD.value = undefined,
  addRdDetails.value = true
}
function handleRdAddDetailsRequest(){
  if(!RdDuration.value){
    showToast('Error', 'Please Enter Duration', 'error')
    return  
  }
  if(!generalInterestRateRD.value){
    showToast('Error', 'Please Enter General Interest Rate', 'error')
    return  
  }
  if(!scInterestRateRD.value){
    showToast('Error', 'Please Enter Senior Citizen Interest Rate', 'error')
    return  
  }
  const newRD : RdInterest ={
    id: "RD_"+RdDuration.value,
    duration: Number(RdDuration.value),
    generalInterestRate: Number(generalInterestRateRD.value),
    scInterestRate: Number(scInterestRateRD.value),
  }

  if(index.value !== undefined && index.value >= 0) {
    RdInterestRates.value[index.value].duration = Number(RdDuration.value)
    RdInterestRates.value[index.value].generalInterestRate = Number(generalInterestRateRD.value)
    RdInterestRates.value[index.value].scInterestRate = Number(scInterestRateRD.value)
    index.value = undefined;
    showToast('Success', 'RD Interests updated Successfully', 'success')
  }
  else{
    RdInterestRates.value.push(newRD)
    showToast('Success', 'RD Interests added Successfully', 'success')
  }
  
  addRdDetails.value = false
}
function editRdItem(RDData: RdInterest){
  index.value = undefined
  addRdDetails.value = true
  RdDuration.value = Number(RDData.duration)
  generalInterestRateRD.value = Number(RDData.generalInterestRate)
  scInterestRateRD.value = Number(RDData.scInterestRate)
  index.value = RdInterestRates.value.indexOf(RDData)
}
function deleteRdItem(RDData: RdInterest){
  confirmDialog.require({
    message: `Are you sure you want to delete ${RDData.duration} Year RD scheme?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      RdInterestRates.value.splice(RdInterestRates.value.indexOf(RDData), 1)
    },
    reject(){
    }
  }
  )
}
function clearAllRd(){
  confirmDialog.require({
    message: `Are you sure you want to delete All Recurring Deposit Interest Rates?`,
    header: 'Confimation',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, Delete'
    },
    accept() {
      RdInterestRates.value.splice(0, RdInterestRates.value.length)
    },
    reject(){
    }
  }
  )
}

function saveAllDetails(){
  if(!settings.value?.id){
    id.value = new Date().getTime()
  }
  if(!bankName.value){
    showToast('Bank Name', 'Bank Name Cannot be empty', 'error')
    return
  }
  //if(!ValidationUtility.validateString(bankName.value)){
   // showToast('Bank name cannot be numbers', 'Bank Name should be characters', 'error')
   // return
  //}
  if(!bankRegNo.value){
    showToast('Bank RegNO', 'Bank Reg No Cannot be empty', 'error')
    return
  }
  if(!sbInterestGeneral.value){
    showToast('SB Interest Rate', 'Please Enter General Interest for SB', 'error')
    return
  }
  if(!sbInterestSenior.value){
    showToast('SB Interest Rate', 'Please Enter Senior Interest for SB', 'error')
    return
  }
  if(!pigmyGeneralInterest.value){
    showToast('SB Interest Rate', 'Please Enter Pigmy Interest Rate', 'error')
    return
  }
  isLoading.value = true
  const SaveAllSettings : AllSettingsDetails ={
    id: id.value,
    bankName: bankName.value,
    bankRegNo: bankRegNo.value,
    sbInterestRateGeneral: sbInterestGeneral.value,
    sbInterestRateSC: sbInterestSenior.value,
    sbCalculationFrequency: defaultSbCalculationFrequency.value,
    pigmyInterestRate: pigmyGeneralInterest.value,
    fdInterestRates: fdInterestRates.value,
    recurringInterestRates: RdInterestRates.value,
    loanInterestRates: loanInterestRates.value
  }
  ApiService.saveAllSettings(SaveAllSettings)
    .then(
      (response) => {
        if(response.statusCode == 200){
          showToast("Settings", "Settings Updated Successfully", "success")
        }
      }
    )
    .catch((error) => {
      showToast("Settings", JSON.parse(CryptoUtil.decrypt(error.response.data)).error, "error")
    })
    .finally(() => {
      isLoading.value = false
    })
}


function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}

</script>
<template>
    <Toast class="error-p-toast" />
    <main class="main" v-bind="$attrs">
        <Card class="search-card">
            <template #title>
                <h2 class="title-right text-3xl font-bold">Settings</h2>
            </template>
            <template #content >
                <Divider/>
                <Fieldset style="text-align: left; margin: 10px" legend="Bank Details">
                <div class="form">
                    <FloatLabel>
                    <InputText id="bankName" v-model="bankName" class="input-text" size="large" />
                    <label for="bankName" class="mandatory">Full name of the bank</label>
                    </FloatLabel>
                    <FloatLabel>
                    <InputText id="bankRegNo" v-model="bankRegNo" class="input-text" />
                    <label for="bankRegNo" class="mandatory">Bank Registration Number</label>
                    </FloatLabel>
                </div>
                </Fieldset>

                <Fieldset style="text-align: left; margin: 10px" legend="Savings Bank Interest Rates">
                <div class="form">
                    <FloatLabel>
                    <InputNumber id="sbInterestGeneral" v-model="sbInterestGeneral" class="input-text" size="large" suffix="%" :max="25" :min="0" :max-fraction-digits="2"/>
                    <label for="sbInterestGeneral" class="mandatory">SB Interest Rate (Per Annum) - General</label>
                    </FloatLabel>
                    <FloatLabel>
                    <InputNumber id="sbInterestSenior" v-model="sbInterestSenior" class="input-text" size="large" suffix="%" :max="25" :min="0" :max-fraction-digits="2" />
                    <label for="sbInterestSenior" class="mandatory">SB Interest Rate (Per Annum) - Senior Citizen</label>
                    </FloatLabel>
                    <div style="display: flex; justify-content: center">
                    <label style="align-content: center; margin-right: 20px" class="mandatory">Calculation frequency</label>
                    <SelectButton :options="sbCalculationFrequencyOptions" v-model="defaultSbCalculationFrequency" />
                    </div>
                </div>
                <div style="display: flex; justify-content: flex-end; margin-top: 20px">
                    <label style="font-size: 12px; color: #181818; font-style: italic">Note: Default frequency to calculate savings bank interest will be<b>"Quarterly"</b></label>
                </div>
                </Fieldset>

                <Fieldset style="text-align: left; margin: 10px" legend="Pigmy Interest Rates">
                <div class="form">
                    <FloatLabel>
                    <InputNumber id="pigmyGeneralInterest" v-model="pigmyGeneralInterest" class="input-text" size="large" :min="0"  suffix="%" :max="25" :max-fraction-digits="2" />
                    <label for="pigmyGeneralInterest" class="mandatory">General Interest Rate for Pigmy</label>
                    </FloatLabel>
                  </div>
                </Fieldset>

                <Fieldset style="text-align: left; margin: 10px" legend="Fixed Deposit Interest Rates">
                    <Divider v-if="fdInterestRates.length <= 0" type="dashed"/>
                    <DataTable v-if="fdInterestRates.length > 0" :value="fdInterestRates" tableStyle="min-width: 50rem">
                        <Column header="Duration" style="width: 25%">
                            <template #body="slotProps">
                                {{ slotProps.data.startDuration +" "+ slotProps.data.startDurationType}} -
                                {{ slotProps.data.endDuration +" "+ slotProps.data.endDurationType}}
                            </template>
                        </Column>
                        <Column field="generalInterestRate" header="General Interest Rate (%)" style="width: 20%"/>
                        <Column field="scInterestRate" header="Senior Citizen Interest Rate (%)"/>
                        <Column header="Actions" bodyStyle="text-align: center; width: 8rem;">
                            <template #body="slotProps">
                                <Button icon="fas fa-pencil" class="p-button-rounded p-button-text p-button-info" @click="editFDItem(slotProps.data)" />
                                <Button icon="fas fa-trash" class="p-button-rounded p-button-text p-button-danger" @click="deleteFDItem(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <label v-else style="color: #181818;">No FD Interest Rates Exist</label>
                    <div style="display: flex; justify-content: flex-end; margin-top: 20px;gap: 1rem">
                        <CustomButton v-if="fdInterestRates.length > 0"  title="Clear all interest rates" @click="clearAllFD" :outlined="true" icon="fas fa-remove"/>
                        <CustomButton title="Add FD Interest Rates" @click="handleAddFDDetails" icon="fas fa-add"/>
                    </div>
                </Fieldset>

                <Fieldset style="text-align: left; margin: 10px" legend="Recurring Deposit Interest Rates">
                    <Divider v-if="RdInterestRates.length <= 0" type="dashed"/>
                    <DataTable v-if="RdInterestRates.length > 0" :value="RdInterestRates" tableStyle="min-width: 50rem">
                        <Column header="Duration" style="width: 25%">
                            <template #body="slotProps">
                                {{ slotProps.data.duration }} Years
                            </template>
                        </Column>
                        <Column field="generalInterestRate" header="General Interest Rate (%)" style="width: 20%"/>
                        <Column field="scInterestRate" header="Senior Citizen Interest Rate (%)"/>
                        <Column header="Actions" bodyStyle="text-align: center; width: 8rem;">
                            <template #body="slotProps">
                                <Button icon="fas fa-pencil" class="p-button-rounded p-button-text p-button-info" @click="editRdItem(slotProps.data)" />
                                <Button icon="fas fa-trash" class="p-button-rounded p-button-text p-button-danger" @click="deleteRdItem(slotProps.data)" />
                            </template>
                        </Column>
                    </DataTable>
                    <label v-else style="color: #181818;">No RD Interest Rates Exist</label>
                    <div style="display: flex; justify-content: flex-end; margin-top: 20px;gap: 1rem">
                        <CustomButton v-if="RdInterestRates.length > 0"  title="Clear all interest rates" @click="clearAllRd" :outlined="true" icon="fas fa-remove"/>
                        <CustomButton title="Add RD Interest Rates" @click="handleAddRdDetails" icon="fas fa-add"/>
                    </div>
                </Fieldset>

                <Fieldset style="text-align: left; margin: 10px" class="fieldsets" legend="Loan Interest Rates">
                    <Divider v-if="loanInterestRates.length <= 0" type="dashed"/>
                    <DataTable v-if="loanInterestRates.length > 0" :value="loanInterestRates" tableStyle="min-width: 50rem">
                        
                        <Column header="Loan Type" style="width: 25%">
                            <template #body="slotProps">
                                {{slotProps.data.loanType}}
                            </template>
                        </Column>
                        <Column field="generalInterestRates" header="General Interest Rate (%)"/>
                        <Column field="scInterestRates" header="Senior Citizen Interest Rate (%)"/>
                        <Column header="Actions" bodyStyle="text-align: center; width: 8rem;">
                        <template #body="slotProps">
                            <Button icon="fas fa-pencil" class="p-button-rounded p-button-text p-button-info" @click="editLoanItem(slotProps.data)" />
                            <Button icon="fas fa-trash" class="p-button-rounded p-button-text p-button-danger" @click="deleteLoanItem(slotProps.data)" />
                        </template>
                        </Column>
                    </DataTable>
                    <label v-else style="color: #181818;">No Loan Schemes found</label>
                    <div style="display: flex; justify-content: flex-end; margin-top: 20px;gap: 1rem">
                        <CustomButton v-if="loanInterestRates.length > 0" title="Clear all interest rates" @click="clearAllLoan" :outlined="true" icon="fas fa-remove"/>
                        <CustomButton title="Add Loan Interest Rates" @click="handleLoanAddDetails" icon="fas fa-add"/>
                    </div>
                </Fieldset>


                <Dialog 
                    v-model:visible="addFDInterestDetails" 
                    :draggable="false" modal 
                    :style="{ width: '40rem' }" 
                    :closable="false"
                >
                    <span class="dialog-header">FD Duration and Interest Rates</span>
                    <Divider />
                    <div class="dialog-form">
                        <FloatLabel>
                            <InputText class="input-text" id="startDuration" v-model="startDuration"></InputText>
                            <label for="startDuration" class="mandatory">Start Duration</label>
                        </FloatLabel>
                        <FloatLabel >
                            <Select
                            v-model="startDurationType"
                            inputId="startDay"
                            :options="option1"
                            optionLabel="name"
                            option-value="name"
                            class="dropdown-select"
                            overlayClass="dropdown-overlay"
                            />
                            <label for="startDay" class="mandatory">Select Duration</label>
                        </FloatLabel>
                        <FloatLabel>
                            <InputText class="input-text" id="endDuration" v-model="endDuration"></InputText>
                            <label for="endDuration" class="mandatory">End Duration</label>
                        </FloatLabel>
                        <FloatLabel>
                            <Select
                            v-model="endDurationType"
                            inputId="endDay"
                            :options="option1"
                            optionLabel="name"
                            option-value="name"
                            class="dropdown-select"
                            overlayClass="dropdown-overlay"
                            />
                            <label for="endDay" class="mandatory">Select Duration</label>
                        </FloatLabel>
                        <FloatLabel>
                            <InputNumber id="generalInterestRate" class="input-text" v-model="generalInterestRateFD" suffix="%" :max="25" :min="0"  :max-fraction-digits="2"></InputNumber>
                            <label for="generalInterestRate" class="mandatory">General Interest Rate (%)</label>
                        </FloatLabel>
                        <FloatLabel>
                            <InputNumber id="seniorInterestRate" class="input-text" v-model="scInterestRateFD" suffix="%" :max="25" :min="0"  :max-fraction-digits="2"></InputNumber>
                            <label for="seniorInterestRate" class="mandatory">Senior Citizen Interest Rate (%)</label>
                        </FloatLabel>
                    </div>
                    <template #footer>
                        <CustomButton :outlined="true" title="close" icon="times" @click="addFDInterestDetails = false" />
                        <CustomButton title="Save" icon="check" @click="handleFDAddDetailsRequest" />
                    </template>
                </Dialog>

                <Dialog 
                    v-model:visible="addRdDetails" 
                    :draggable="false" 
                    modal :style="{ width: '40rem' }" 
                    :closable="false"
                >
                    <span class="dialog-header">RD Interest Rates</span>
                    <Divider />
                    <div class="dialog-form">
                        <FloatLabel>
                            <InputNumber v-model="RdDuration" id="duration" class="input-text"  size="large" suffix=" Year" :min="0"  :max="99" :max-fraction-digits="0" />
                            <label for="duration" class="mandatory">Duration</label>
                        </FloatLabel><br>
                        <FloatLabel>
                            <InputNumber id="generalInterestRate" class="input-text" v-model="generalInterestRateRD" suffix="%" :min="0"  :max="25" :max-fraction-digits="2"></InputNumber>
                            <label for="generalInterestRate" class="mandatory">General Interest Rate (%)</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber id="seniorInterestRate" class="input-text" v-model="scInterestRateRD" suffix="%" :max="25" :min="0" :max-fraction-digits="2"></InputNumber>
                            <label for="seniorInterestRate" class="mandatory">Senior Interest Rate (%)</label>
                        </FloatLabel>
                    </div>
                    <template #footer>
                        <CustomButton :outlined="true" title="close" icon="times" @click="addRdDetails = false" />
                        <CustomButton title="Save" icon="check" @click="handleRdAddDetailsRequest" />
                    </template>
                </Dialog>

                <Dialog
                    v-model:visible="loanDetailsAdd"
                    :draggable="false"
                    modal
                    :style="{ width: '40rem' }"
                    :closable="false"
                >
                    <span class="dialog-header">Add Loan and Loan Interest Rates</span>
                    <Divider />
                    <div class="dialog-form">
                    <FloatLabel >
                        <Select
                        v-model="loanType"
                        inputId="startDay"
                        :options="option2"
                        optionLabel="name"
                        option-value="name"
                        class="dropdown-select"
                        overlayClass="dropdown-overlay"
                        />
                        <label for="branchCode" class="mandatory">Select Type of Loan</label>
                    </FloatLabel>
                    <br>
                    <FloatLabel>
                      <InputNumber v-model="generalInterestRateLoan" id="generalInterestRate" class="input-text" suffix="%" :min="0"  :max="25" :max-fraction-digits="2" />
                        <label for="generalInterestRate" class="mandatory">General Interest Rate(%)</label>
                    </FloatLabel>
                    <FloatLabel>
                      <InputNumber v-model="seniorInterestRateLoan" id="seniorInterestRate" class="input-text"  suffix="%" :min="0" :max="25" :max-fraction-digits="2" />
                        <label for="seniorInterestRate" class="mandatory">Senior Citizen Interest Rate(%)</label>
                    </FloatLabel>
                    </div>
                    <template #footer>
                    <CustomButton :outlined="true" title="close" icon="times" @click="loanDetailsAdd = false" />
                    <CustomButton title="Save" icon="check" @click="handleLoanAddDetailsRequest" />
                    </template>
                </Dialog>

            </template>

        </Card>
        <Card class="bottom-sheet" style="padding: 0;">
          <template #footer>
            <div>
              <CustomButton title="Submit" @click="saveAllDetails" class="right-button" icon="fas fa-check" />
            </div>
          </template>
          
        </Card>
    </main>


    <div v-if="isLoading">
      <LoadingComponent
        text="Please wait while we process the request!"
        :dialogVisiblity="isLoading"
      />
    </div>
    <ConfirmDialog :draggable="false" :closable="false" />

</template>
<style scoped>
.main{
  padding-bottom: 80px;
}
.form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.dialog-form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}
.mandatory::after {
  content: ' *';
  color: red;
}
.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10%;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.input-text {
  height: 45px;
}
.dropdown-select .p-dropdown-item {
  color: rgb(50, 95, 186);
}
.dropdown-overlay {
  --p-select-overlay-color: rgb(50, 95, 186);
}
.dropdown-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue;
}
</style>