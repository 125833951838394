import router from '@/router'
import type { MenuItem } from 'primevue/menuitem'

export default class MenuItems {
  public static MENU_ITEMS_ADMIN: MenuItem[] = [
    {
      label: 'Customers',
      icon: 'fas fa-user',
      items: [
        {
          label: 'Create Customer',
          icon: 'fas fa-add',
          command: () => {
            router.push({
              name: 'createCustomer'
            })
          }
        },
        {
          label: 'Search Customer',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchCustomer'
            })
          }
        }
      ]
    },
    {
      label: 'Accounts',
      icon: 'fas fa-book',
      command: () => {
        router.push({
          name: 'searchAccount'
        })
      }
    },
    {
      label: 'Loans',
      icon: 'fas fa-money-check-alt',
      items: [
        {
          label: 'Loan Application',
          icon: 'fas fa-file',
          items: [
            {
              label: 'Apply',
              icon: 'fas fa-plus',
              command: () => {
                router.push({
                  name: 'applyLoan'
                })
              }
            },
            {
              label: 'Status',
              icon: 'fas fa-hourglass-start',
              command: () => {
                router.push({
                  name: 'loanStatus'
                })
              }
            },
            {
              label: 'Approve',
              icon: 'fas fa-check',
              command: () => {
                router.push({
                  name: 'approveLoan'
                })
              }
            }
          ]
        },
        {
          label: 'Search Loan',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchLoan'
            })
          }
        },
      ]
    },

    {
      label: 'FD',
      icon: 'fas fa-piggy-bank',
      command: () => {
        router.push({
          name: 'fdView'
        })
      }
    },
    {
      label: 'Pigmy',
      icon: 'fas fa-money-bill',
      items: [
        {
          label: 'Create Pigmy',
          icon: 'fas fa-add',
          command: () => {
            router.push({
              name: 'createPigmy'
            })
          }
        },
        {
          label: 'Search Pigmy',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchPigmy'
            })
          }
        },
        {
          label: 'Approve Pigmy',
          icon: 'fas fa-check',
          command: () => {
            router.push({
              name: 'approvePigmy'
            })
          }
        }
      ]
    },
    {
      label: 'Shareholders',
      icon: 'fas fa-users',
      command: () => {
        router.push({
          name: 'shareholderView'
        })
      }
    },
    {
      label: 'Operations',
      icon: 'fas fa-desktop',
      items: [
        {
          label: 'Employee',
          icon: 'fas fa-user-tie',
          command: () => {
            router.push({
              name: 'employeeView'
            })
          }
        },
        {
          label: 'Branches',
          icon: 'fas fa-warehouse',
          command: () => {
            router.push({
              name: 'branchesView'
            })
          }
        },
        {
          label: 'Income and Expenses',
          icon: 'fas fa-chart-line',
          command: () => {
            router.push({
              name: 'incomeAndExpenses'
            })
          }
        }
      ]
    },
    {
      label: 'Reports',
      icon: 'fas fa-file',
      items: [
        {
          label: 'Pigmy Reports',
          icon: 'fas fa-money-bill',
          command: () => {
            router.push({
              name: 'pigmyReports'
            })
          }
        },
        {
          label: 'SB Reports',
          icon: 'fas fa-book',
          command: () => {
            router.push({
              name: 'savingsReport'
            })
          }
        },
        {
          label: 'Customer Report',
          icon: 'fas fa-user',
          command: () => {
            router.push({
              name: 'customerReport'
            })
          }
        },
        {
          label: 'Shareholder Report',
          icon: 'fas fa-users',
          command: () => {
            router.push({
              name: 'shareholderReport'
            })
          }
        },
        {
          label: 'FD Report',
          icon: 'fas fa-piggy-bank',
          command: () => {
            router.push({
              name: 'fdReport'
            })
          }
        },
        {
          label: 'Loan Report',
          icon: 'fas fa-money-check-alt',
          command: () => {
            router.push({
              name: 'loanReport'
            })
          }
        }
      ]
    }
  ]
  public static MENU_ITEMS_L1: MenuItem[] = [
    {
      label: 'Customers',
      icon: 'fas fa-user',
      items: [
        {
          label: 'Create Customer',
          icon: 'fas fa-add',
          command: () => {
            router.push({
              name: 'createCustomer'
            })
          }
        },
        {
          label: 'Search Customer',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchCustomer'
            })
          }
        }
      ]
    },
    {
      label: 'Accounts',
      icon: 'fas fa-book',
      command: () => {
        router.push({
          name: 'searchAccount'
        })
      }
    },
    {
      label: 'Loans',
      icon: 'fas fa-money-check-alt',
      items: [
        {
          label: 'Loan Application',
          icon: 'fas fa-file',
          items: [
            {
              label: 'Apply Loan',
              icon: 'fas fa-plus',
              command: () => {
                router.push({
                  name: 'applyLoan'
                })
              }
            },
            {
              label: 'Status',
              icon: 'fas fa-hourglass-start',
              command: () => {
                router.push({
                  name: 'loanStatus'
                })
              }
            }
          ]
        },
        {
          label: 'Search Loan',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchLoan'
            })
          }
        }
      ]
    },
    {
      label: 'FD',
      icon: 'fas fa-piggy-bank',
      command: () => {
        router.push({
          name: 'fdView'
        })
      }
    },
    {
      label: 'Pigmy',
      icon: 'fas fa-money-bill',
      items: [
        {
          label: 'Create Pigmy',
          icon: 'fas fa-add',
          command: () => {
            router.push({
              name: 'createPigmy'
            })
          }
        },
        {
          label: 'Search Pigmy',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchPigmy'
            })
          }
        }
      ]
    },
    {
      label: 'Shareholders',
      icon: 'fas fa-users',
      command: () => {
        router.push({
          name: 'shareholderView'
        })
      }
    }
  ]
  public static MENU_ITEMS_L2: MenuItem[] = [
    {
      label: 'Customers',
      icon: 'fas fa-user',
      items: [
        {
          label: 'Create Customer',
          icon: 'fas fa-add',
          command: () => {
            router.push({
              name: 'createCustomer'
            })
          }
        },
        {
          label: 'Search Customer',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchCustomer'
            })
          }
        }
      ]
    },
    {
      label: 'Accounts',
      icon: 'fas fa-book',
      command: () => {
        router.push({
          name: 'searchAccount'
        })
      }
    },
    {
      label: 'Loans',
      icon: 'fas fa-money-check-alt',
      items: [
        {
          label: 'Loan Status',
          icon: 'fas fa-hourglass-start',
          command: () => {
            router.push({
              name: 'loanStatus'
            })
          }
        },
        {
          label: 'Search Loan',
          icon: 'fas fa-search',
          command: () => {
            router.push({
              name: 'searchLoan'
            })
          }
        }
      ]
    },
    {
      label: 'FD',
      icon: 'fas fa-piggy-bank',
      command: () => {
        router.push({
          name: 'fdView'
        })
      }
    },
    {
      label: 'Pigmy',
      icon: 'fas fa-money-bill',

      command: () => {
        router.push({
          name: 'searchPigmy'
        })
      }
    },
    {
      label: 'Shareholders',
      icon: 'fas fa-users',
      command: () => {
        router.push({
          name: 'shareholderView'
        })
      }
    }
  ]
  public static MENU_ITEMS_L3: MenuItem[] = [
    {
      label: 'Customers',
      icon: 'fas fa-user',

      command: () => {
        router.push({
          name: 'searchCustomer'
        })
      }
    },
    {
      label: 'Accounts',
      icon: 'fas fa-book',
      command: () => {
        router.push({
          name: 'searchAccount'
        })
      }
    },
    {
      label: 'Pigmy',
      icon: 'fas fa-money-bill',

      command: () => {
        router.push({
          name: 'searchPigmy'
        })
      }
    }
  ]
}
