<script setup lang="ts">
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import ApiService from '../api/ApiService'
import type { LoginRequest } from '@/api/models/login/LoginRequest'
import Storage from '@/utils/Storage'
import type { ToastMessageOptions } from 'primevue/toast'
import * as pack from '../../package.json'
import { DynatraceManager } from '@/utils/DynatraceManager'
const toast = useToast()
const username = ref('')
const password = ref('')
const passwordVisible = ref(false)
const router = useRouter()
let isLoading = ref(false)

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value
}

DynatraceManager.sendStringSessionProperties({
  "version": pack.version.toString()
})

async function login() {
  if (!username.value) {
    toast.add({
      severity: 'error',
      summary: 'Invalid Credentials',
      detail: 'Username cannot be blank/empty',
      life: 3000
    })
    return
  }

  if (!password.value) {
    toast.add({
      severity: 'error',
      summary: 'Invalid Credentials',
      detail: 'Password cannot be blank/empty',
      life: 3000
    })
    return
  }

  isLoading.value = true
  const request: LoginRequest = {
    userName: username.value,
    password: password.value
  }

  await ApiService.login(request)
    .then((response) => {
      if (response.statusCode == 200) {
        DynatraceManager.identifyUser(response.data?.empData?.userName!!)
        DynatraceManager.sendNumberSessionProperties({empid: response.data?.empData?.empId!!})
        Storage.setLoginResponse(response)
        router.push({
          name: 'main'
        })
      } else {
        showToast('Login failed', response.error!!, 'error')
      }
    })
    .catch((error) => {
      DynatraceManager.reportError(error)
      toast.add({
        severity: 'error',
        summary: 'Login failed',
        detail: error,
        life: 3000
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
    <main class="background-container ">
        <Toast class="error-p-toast" />
        <Card class="login-card">
            <template #title>
                <div class="title-right">Login</div>
            </template>
            <template #subtitle>
                <div class="subtitle-right">Provide your login credentials to access.</div>
            </template>
            <template #content>
                <div>
                    <Divider type="solid" />
                    <div class="p-fluid">
                        <div class="p-field">
                            <FloatLabel>
                                <InputText id="username" v-model="username" class="input-text" size="large" />
                                <label for="username">Username</label>
                            </FloatLabel>
                        </div>
                        <div class="p-field">
                            <FloatLabel>
                                <InputText :type="passwordVisible ? 'text' : 'password'" size="large" id="password"
                                    v-model="password" class="input-text" @keydown.enter="login"/>
                                <label for="password">Password</label>
                                <i :class="passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'" class="toggle-password"
                                    @click="togglePasswordVisibility"></i>
                            </FloatLabel>
                        </div>
                        <Button label="Login" class="login-button" :disabled="isLoading" @click="login"></Button>
                        <ProgressBar mode="indeterminate" v-if="isLoading" style="height: 6px" class="progress-bar">
                        </ProgressBar>
                    </div>
                </div>
            </template>
        </Card>
        <div class="bottom-left-text">Bhavsar Patini Sahakar Sanga(R), Badami, Tq:Badami, Dist:Bagalkot </div>
        <div class="bottom-middle">v.{{ pack.version }}</div>
        <div class="bottom-right-text">Powered by <b>Matrex Softwares</b></div>
    </main>
</template>

<style>
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.background-container {
  width: 100vw;
  height: 100vh;
  background-image: url('../assets/background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  width: 22%;
  height: 25rem;
  display: flex;
  justify-self: center;
  background-color: white;
  --p-card-border-radius: 20px;
}

.input-text {
  width: 100%;
  height: 50px;
  --p-inputtext-focus-border-color: rgb(50, 95, 186);
}

.title-right {
  text-align: left;
  width: 100%;
  font-size: 26px;
  margin-left: 10px;
  margin-top: 10px;
}

.subtitle-right {
  text-align: left;
  width: 100%;
  margin-left: 10px;
}

.p-field {
  margin-bottom: 1rem;
  margin-top: 2rem;
  width: 100%;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.login-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  height: 50px;
  --p-button-primary-background: rgb(50, 95, 186);
  --p-button-border-radius: 10px;
  --p-button-primary-border-color: rgb(50, 95, 186);
  --p-button-primary-hover-background: rgb(82, 116, 184);
  --p-button-primary-active-background: rgb(50, 95, 186);
  --p-button-primary-hover-border-color: rgb(82, 116, 184);
  cursor: pointer;
  /* Add pointer cursor on hover */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  /* Adjust font size */
}

.bottom-left-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #000000;
  /* Example text color */
  font-size: 16px;
  font-weight: 300;
}

.bottom-right-text {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
}

.bottom-middle {
  position: absolute;
  bottom: 20px;
  left: 47vw;
  color: #000000;
  font-size: 16px;
  font-weight: 300;
}

.progress-bar {
  --p-progressbar-value-background: rgb(216, 216, 216);
  --p-progressbar-background: rgb(50, 95, 186);
  --p-progressbar-border-radius: 40px;
  margin-top: 1rem;
}

.error-p-toast {
  font-family: Epilogue, sans-serif;
  --p-toast-border-radius: 20px;
  --p-toast-error-background: rgba(196, 69, 69, 0.5);
  --p-toast-error-border-color: rgb(255, 0, 0);
  --p-toast-error-color: rgb(255, 255, 255);
  --p-toast-error-detail-color: rgb(255, 255, 255);
  --p-toast-success-background: rgba(17, 210, 30, 0.517);
  --p-toast-success-border-color: rgba(118, 205, 150, 0.735);
  --p-toast-success-color: rgba(0, 0, 0, 0.892);
}
</style>
