import type { LoginResponse } from "@/api/models/login/LoginResponse";

export default class Storage {

    private static loginResponseData?: LoginResponse = undefined

    public static setLoginResponse(loginResponse: LoginResponse): void {
        this.loginResponseData = loginResponse;
    }

    public static getToken(): string {
        return this.loginResponseData?.data?.token ?? '';
    }

    public static getUserName(): string {
        return this.loginResponseData?.data?.empData.userName ?? '';
    }

    public static getEmpName(): string {
        if (!this.loginResponseData?.data?.empData.firstName) {
            return '';
        }
        if (!this.loginResponseData?.data?.empData.lastName) {
            return '';
        }
        return `${this.loginResponseData?.data?.empData.firstName} ${this.loginResponseData?.data?.empData.lastName}`;
    }

    public static getEmpId(): number | null {
        return this.loginResponseData?.data?.empData.empId ?? null
    }

    public static getEmpAccessRole(): string | null {
        return this.loginResponseData?.data?.empData?.accessRole ?? null
    }

    public static getEmpBranchCode(): string | null {
        return this.loginResponseData?.data?.empData?.branchCode ?? null
    }
    
    public static clearData(): void {
        this.loginResponseData = undefined
    }
}