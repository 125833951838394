import { SiteSettings } from './SiteSettings'

export default class DateUtils {
  static getTimestampFromTodayDays(days: number): number {
    const today = new Date()
    today.setDate(today.getDate() + days)
    return today.getTime()
  }
  static getTimestampFromTodayMonths(months: number): number {
    const today = new Date()
    today.setMonth(today.getMonth() + months)
    return today.getTime()
  }
  //This function is used to calculate timestamp when we add particular days to a date

  static getTimestampFromTwoDates(startDate: Date, days: number): number {
    const today = new Date(startDate)
    today.setDate(today.getDate() + days)
    return today.getTime()
  }

  static dateDifference(startDate: number, endDate: number) {
    const start = new Date(startDate)
    const end = new Date(endDate)

    let years = end.getFullYear() - start.getFullYear()
    let months = end.getMonth() - start.getMonth()
    let days = end.getDate() - start.getDate()

    // Adjust days if negative
    if (days < 0) {
      months -= 1
      const daysInPrevMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate()
      days += daysInPrevMonth
    }

    // Adjust months if negative
    if (months < 0) {
      years -= 1
      months += 12
    }
    return `${years ? `${years > 1 ? `${years} years ` : `${years} year `}` : ''}${months ? `${months > 1 ? `${months} months ` : `${months} month `}` : ''}${days ? `${days} days` : ''}`
  }

  static getYearsDifference(startTimestamp: number, endTimestamp: number) {
    // Milliseconds in a year (approximate, accounting for leap years)
    const millisecondsInYear = 365.25 * 24 * 60 * 60 * 1000

    // Difference in milliseconds
    const diffInMilliseconds = endTimestamp - startTimestamp

    // Convert to years
    const yearsDifference = diffInMilliseconds / millisecondsInYear

    return Number(yearsDifference.toFixed(2))
  }

  static isSeniorCitizen(birthDate: number) {
    const currentDate = new Date().getTime() // Get the current time in milliseconds
    const ageInMilliseconds = currentDate - birthDate // Calculate the age in milliseconds

    const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25 // Account for leap years (365.25 days per year)
    const ageInYears = ageInMilliseconds / millisecondsPerYear

    return ageInYears >= SiteSettings.getSCAge()
  }

static getFutureDateInMilliseconds(day: number, month: number, startDate: Date) {
    const today = startDate;
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const targetDate = new Date(currentYear, currentMonth + (month -1), day);

    return targetDate.getTime();
}

  static emiDate(day:number, month:number,start:number) {
    const today = new Date(start);
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); 

  const targetDate = new Date(currentYear, currentMonth + (month - 1), day);

  return targetDate.getTime();
  }

  static monthDifference(startDate:Date, endDate:Date) {
    // Get the year and month for both dates
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth(); // Months are zero-based in JS
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    // Calculate the total difference in months
    let monthDiff = (endYear - startYear) * 12 + (endMonth - startMonth);

    // Adjust for cases where the day of the start month is later than the end month
    if (endDate.getDate() < startDate.getDate()) {
        monthDiff--;
    }

    return monthDiff;
}
}
