export class SiteSettings{
    static getSCAge(){
        return 60
    }

    static getFDInterestType(){
        return 'Compound'
    }

    static getLoanInterestType(){

        return 'Compound'
    }

    static getPigmyInterestType(){
        return 'Simple'
    }
}