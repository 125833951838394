<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import ConversionUtility from '../utils/ConversionUtility'
import { useToast } from 'primevue/usetoast'
import type { ToastMessageOptions } from 'primevue/toast'
import ValidationUtility from '@/utils/ValidationUtility'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { LoanScheme } from '@/api/models/setting/LoanScheme'
import DateUtils from '@/utils/DateUtils'
import type { Document } from '@/models/Document'
import { InterestAndTotalCalculator } from '@/utils/InterestAndTotalCalculator'
import type { ApplyLoanRequest } from '@/api/models/loan/ApplyLoanRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import FloatLabel from 'primevue/floatlabel'
import type { ProfessionType } from '@/models/ProfessionType'
import type { AmortizationEMISchedule } from '@/models/AmortizationEMISchedule'
import { useConfirm } from 'primevue/useconfirm'
import { PDFGenerator } from '@/utils/PDFGenerator'

const toast = useToast()
const confirmDialog = useConfirm()
const isLoading = ref<boolean>(false)
const isApplyLoanVisible = ref<boolean>(false)
const showEmiSchedule = ref<boolean>(false)
const loanCreatedSuccessfully = ref<boolean>(false)
const minDate = new Date(new Date().setMonth(new Date().getMonth() - 3))
const maxDate = new Date()

const customer = ref<CustomerDetailsData>()
const activeStep = ref<string>('1')
const applicationId = ref<string>()

const LoanSchemes = ref<LoanScheme[]>([])
const selectedScheme = ref<LoanScheme>()
const guarantor1 = ref<CustomerDetailsData>()
const guarantor2 = ref<CustomerDetailsData>()
const director = ref<CustomerDetailsData>()
// step 2 valibles
const incomePerAnnum = ref<number>()
const incomePerMonth = ref<number>()
const employerName = ref<string | null>()
const businessDetails = ref<string | null>()
const agricultureDetails = ref<string | null>()
const collateralDetails = ref<string>()
const purpose = ref<string>()
const documentSubmitted = ref<string[]>([])
const professionTypes = ref<ProfessionType[]>([
  { name: 'Employed' },
  { name: 'Business' },
  { name: 'Agriculture' }
])
const documents = ref<Document[]>([
  { name: 'Pay slip', type: 'employed' },
  { name: 'Residence Proof', type: 'employed' },
  { name: 'Employment Proof', type: 'employed' },
  { name: 'Income Proof', type: 'employed' },
  { name: 'Cheque', type: 'employed' },
  { name: 'Bond', type: 'employed' },
  { name: 'Shop act license', type: 'business' },
  { name: 'Gst registration', type: 'business' },
  { name: 'Residence Proof', type: 'business' },
  { name: 'Income Proof', type: 'business' },
  { name: 'Cheque', type: 'business' },
  { name: 'Bond', type: 'business' },
  { name: 'Income Proof', type: 'agriculture' },
  { name: 'Residence Proof', type: 'agriculture' },
  { name: 'Cheque', type: 'agriculture' },
  { name: 'Bond', type: 'agriculture' }
])
const professionType = ref<ProfessionType>(professionTypes.value[0])
const displayDocuments = ref<Document[]>([])

// step 3 variables
const loanInterestRate = ref<number>()
const loanAmount = ref<number>()
const loanTenure = ref<number>()
const processingFees = ref<number>()
const buildingFees = ref<number>()
const miscellaneousFees = ref<number>()
const sharePercent = ref<number>()
const shareholderTransferAmount = ref<number>()
const shareFee = ref<number>()
const loanStartDate = ref<Date>(new Date())
const totalLoanAmount = ref<number>()
const sbTransferAmount = ref<number>()
const emiSchedule = ref<AmortizationEMISchedule[]>([])
const totalLoanPayableAmount = ref<number>()
const emiDate = ref<number>()
const interestAmount = ref<number>()
const applyLoanRequest = ref<ApplyLoanRequest>()

function handelSearchCustomer(query: string) {
  isApplyLoanVisible.value = false
  customer.value = undefined
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    return
  }
  if (query.length < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: CustomerDetailsRequest = { cusId: query }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customer.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function getLoanSchemes() {
  isLoading.value = true
  ApiService.getLoanSchemes()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        isApplyLoanVisible.value = true
        LoanSchemes.value = response.data
      } else {
        showToast(
          'Failed to fetch Loan Schemes',
          'No loan schemes found. Pls add loan schemes from settings.',
          'error'
        )
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Loan Schemes', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function onProfessionSelectChange() {
  displayDocuments.value = []
  documentSubmitted.value = []
  employerName.value = undefined
  businessDetails.value = undefined
  agricultureDetails.value = undefined
  incomePerAnnum.value = undefined
  incomePerMonth.value = undefined
  collateralDetails.value = undefined

  if (professionType.value.name == 'Employed') {
    displayDocuments.value = documents.value.filter(
      (x) => x.type === professionType.value.name.toLocaleLowerCase()
    )
  } else if (professionType.value.name == 'Business') {
    displayDocuments.value = documents.value.filter(
      (x) => x.type === professionType.value.name.toLocaleLowerCase()
    )
  } else if (professionType.value.name == 'Agriculture') {
    displayDocuments.value = documents.value.filter(
      (x) => x.type === professionType.value.name.toLocaleLowerCase()
    )
  }
}

function validateStep2() {
  if (professionType.value.name === 'Employed' && !employerName.value) {
    showToast(
      'Employer Name and Business Details is empty/blank',
      'Enter a either employer name or business details',
      'error'
    )
    return
  }
  if (professionType.value.name === 'Employed' && !ValidationUtility.validateString(employerName.value?.toString()!)) {
    showToast(
      'Employer Name and Business Details should contain only characters',
      'Enter only characters',
      'error'
    )
    return
  }
  if (professionType.value.name === 'Employed' && employerName.value?.toString().length! < 3) {
    showToast(
      'Employer Name and Business Details not correct',
      'Please enter more than 3 characters',
      'error'
    )
    return
  }
  if (professionType.value.name === 'Business' && !businessDetails.value) {
    showToast('Business Details is empty/blank', 'Enter a valid business details', 'error')
    return
  }
  if (businessDetails.value?.toString().length! < 5) {
    showToast('Business Details error', 'Enter minimum 5 characters', 'error')
    return
  }
  if (!ValidationUtility.validateString(businessDetails.value?.toString()!)) {
    showToast('Business Details error', 'Enter only letters', 'error')
    return
  }
  if (professionType.value.name === 'Agriculture' && !agricultureDetails.value) {
    showToast('Agriculture Details is empty/blank', 'Enter a valid agriculture details', 'error')
    return
  }
  if (!ValidationUtility.validateString(agricultureDetails.value?.toString()!)) {
    showToast('Agriculture Details error', 'Enter only letters', 'error')
    return
  }
  if (!incomePerAnnum.value) {
    showToast('Income Per Annum is empty/blank', 'Enter a valid income per annum', 'error')
    return
  }
  if (professionType.value.name === 'Employed' && !incomePerMonth.value) {
    showToast('Income Per Month is empty/blank', 'Enter a valid profession type', 'error')
    return
  }
  if (incomePerMonth.value) {
    if (Number(incomePerMonth.value) > Number(incomePerAnnum.value)) {
      showToast(
        'Income Per Annum should be greater than Income Per Month',
        'Enter a valid loan interest rate',
        'error'
      )
      return
    }
  }
  if (!collateralDetails.value) {
    showToast('Collateral Details is empty/blank', 'Enter a valid collateral details', 'error')
    return
  }
  if (documentSubmitted.value.length == 0) {
    showToast('Document Submitted is empty/blank', 'Enter a valid document submitted', 'error')
    return
  }
  activeStep.value = '3'
}

function calculateTotalLoanPayableAmount() {
  if (!loanAmount.value) {
    showToast('Principal Amount is empty/blank', 'Enter a valid Principal amount', 'error')
    return
  }
  if (!loanInterestRate.value) {
    showToast('Loan Interest Rate is empty/blank', 'Enter a valid loan interest rate', 'error')
    return
  }
  if (!loanTenure.value) {
    showToast('Loan Tenure is empty/blank', 'Enter a valid loan tenure', 'error')
    return
  }
  if (loanTenure.value) {
    if (Number(loanTenure.value) < 1) {
      showToast('Loan Tenure is less than 1 Month', 'Enter a valid loan tenure', 'error')
      return
    }
  }
  if (!processingFees.value) {
    showToast('Processing Fees is empty/blank', 'Enter a valid processing fees', 'error')
    return
  }
  if (processingFees.value) {
    if (Number(processingFees.value) > Number(loanAmount.value)) {
      showToast(
        'Processing Fees should be less than Principal Amount',
        'Enter a valid processing fees',
        'error'
      )
      return
    }
  }
  if (!buildingFees.value) {
    showToast('Building Fees is empty/blank', 'Enter a valid building fees', 'error')
    return
  }
  if (buildingFees.value) {
    if (Number(buildingFees.value) > Number(loanAmount.value)) {
      showToast(
        'Building Fees should be less than Principal Amount',
        'Enter a valid Building fees',
        'error'
      )
      return
    }
  }
  if (!miscellaneousFees.value) {
    showToast('Miscellaneous Fees is empty/blank', 'Enter a valid miscellaneous fees', 'error')
    return
  }
  if (miscellaneousFees.value) {
    if (Number(miscellaneousFees.value) > Number(loanAmount.value)) {
      showToast(
        'Miscellaneous Fees should be less than Principal Amount',
        'Enter a valid miscellaneous fees',
        'error'
      )
      return
    }
  }
  if (shareFee.value) {
    if (Number(shareFee.value) > Number(loanAmount.value)) {
      showToast(
        'Share Fees should be less than Principal Amount',
        'Enter a valid Share fees',
        'error'
      )
      return
    }
  }
  if (!sharePercent.value) {
    showToast('Share Percent is empty/blank', 'Enter a valid share percent', 'error')
    return
  }
  interestAmount.value = 0
  emiSchedule.value.forEach((x) => (interestAmount.value!! += x.interest))
  interestAmount.value = ConversionUtility.toRuppesCoversion(interestAmount.value!!)
  totalLoanPayableAmount.value = Number(loanAmount.value) + Number(interestAmount.value)
    shareFee.value = ConversionUtility.toRuppesCoversion(
      ((sharePercent.value ?? 0 / 100) * (loanAmount.value ?? 0) -
        customer.value?.shareholder?.shareAmount!!) *
        0.1
    )
  shareFee.value < 0 ? (shareFee.value = 0) : shareFee.value
  if (
    ConversionUtility.toPaiseConversion((sharePercent.value / 100) * loanAmount.value) >
    customer.value?.shareholder?.shareAmount!!
  ) {
    shareholderTransferAmount.value =
      ConversionUtility.toPaiseConversion((sharePercent.value / 100) * loanAmount.value) -
      customer.value?.shareholder?.shareAmount!!
  } else {
    shareholderTransferAmount.value = 0
  }
  sbTransferAmount.value =
    Number(loanAmount.value) -
    (Number(processingFees.value) +
      Number(buildingFees.value) +
      Number(miscellaneousFees.value) +
      ConversionUtility.toRuppesCoversion(shareholderTransferAmount.value) +
      shareFee.value)
  if (
    processingFees.value + miscellaneousFees.value + buildingFees.value + shareFee.value!! >
    sbTransferAmount.value
  ) {
    sbTransferAmount.value = undefined
    showToast(
      'Total Fees should be less than Principal Amount',
      'Enter a valid total fees',
      'error'
    )
    return
  }
}

function validateStep3() {
  confirmDialog.require({
    message: `Are you sure you want to create a loan account for Principal amount ${ConversionUtility.toStringRupees(totalLoanPayableAmount.value ?? 0)}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!totalLoanPayableAmount.value) {
        showToast(
          'Total Loan Payable Amount is empty/blank',
          'Calculate Total Loan Payable Amount',

          'error'
        )
        return
      }
      if (emiSchedule.value.length == 0) {
        showToast('EMI Schedule is empty/blank', 'Calculate EMI Schedule', 'error')
        return
      }
      if (!emiDate.value) {
        showToast('EMI Date is empty/blank', 'Enter a valid EMI Date', 'error')
        return
      }
      activeStep.value = '4'
    },
    reject() {}
  })
}
function handelGuarantor1Search(query: string) {
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    return
  }
  if (query.length < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  if (query == customer.value?.customer?.customerId) {
    showToast(
      'Customer cannot be guarantor for their own loan',
      'Please provide different customer ID',
      'error'
    )
    return
  }
  if (query == guarantor1.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  if (query == director.value?.customer?.customerId) {
    showToast('Director already selected', 'Please provide different customer ID', 'error')
    return
  }
  if (query == guarantor2.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: CustomerDetailsRequest = { cusId: query }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        guarantor1.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelGuarantor2Search(query: string) {
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    return
  }
  if (query.length < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  if (query == customer.value?.customer?.customerId) {
    showToast(
      'Customer cannot be guarantor for their own loan',
      'Please provide different customer ID',
      'error'
    )
    return
  }
  if (query == guarantor1.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  if (query == guarantor2.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  if (query == director.value?.customer?.customerId) {
    showToast('Director already selected', 'Please provide different customer ID', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: CustomerDetailsRequest = { cusId: query }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        guarantor2.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelDirectorSearch(query: string) {
  if (!ValidationUtility.validateNumber(query)) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    return
  }
  if (query.length < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  if (query == customer.value?.customer?.customerId) {
    showToast(
      'Customer cannot be guarantor for their own loan',
      'Please provide different customer ID',
      'error'
    )
    return
  }
  if (query == guarantor1.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  if (query == guarantor2.value?.customer?.customerId) {
    showToast('Guarantor already selected', 'Please provide different customer ID', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: CustomerDetailsRequest = { cusId: query }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })
        }
      }, 2000)
      if (response.statusCode == 200) {
        director.value = response.data
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function applyLoan() {
  confirmDialog.require({
    message: `Are you sure you want to create a loan account ?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!guarantor1.value || !guarantor2.value) {
        showToast('Please select guarantor', 'Please select guarantor', 'error')
        return
      }
      if (!loanAmount.value) {
        showToast('Principal Amount is empty/blank', 'Enter a valid Principal amount', 'error')
        return
      }
      if (!selectedScheme.value?.loanType) {
        showToast('Loan Type is empty/blank', 'Enter a valid loan type', 'error')
        return
      }
      if (!loanTenure.value) {
        showToast('Loan Tenure is empty/blank', 'Enter a valid loan tenure', 'error')
        return
      }

      if (emiDate.value! < 1 || emiDate.value! > 15) {
        showToast('EMI Date is invalid', 'Enter a valid EMI Date', 'error')
        return
      }
      if (!sharePercent.value) {
        showToast('Share Percent is empty/blank', 'Enter a valid Share Percent', 'error')
        return
      }
      if (!processingFees.value) {
        showToast('Processing fees is empty/blank', 'Enter a valid Processing fees', 'error')
        return
      }
      if (!interestAmount.value) {
        showToast('Interest Amount is empty/blank', 'Enter a valid Interest Amount', 'error')
        return
      }
      if (!loanInterestRate.value) {
        showToast('Loan Interest Rate is empty/blank', 'Enter a valid loan interest rate', 'error')
        return
      }
      if (!incomePerAnnum.value) {
        showToast('Income Per Annum is empty/blank', 'Enter a valid Income Per Annum', 'error')
        return
      }
      if (!incomePerMonth.value && employerName.value) {
        showToast('Income Per Month is empty/blank', 'Enter a valid Income Per Month', 'error')
        return
      }
      if (!documentSubmitted.value) {
        showToast('Document Submitted is empty/blank', 'Enter a valid document submitted', 'error')
        return
      }
      if (!businessDetails.value && !agricultureDetails.value && !employerName.value) {
        showToast(
          (professionType.value.name === 'Employed'
            ? 'Employer Details'
            : professionType.value.name === 'Business'
              ? 'Business Details'
              : 'Agriculture Details') + ' is empty/blank.',
          'Enter a valid business details',
          'error'
        )
        return
      }
      if (!collateralDetails.value) {
        showToast('Collateral Details is empty/blank', 'Enter a valid collateral details', 'error')
        return
      }

      isLoading.value = true
      const createBy: CreatedBy = {
        empId: Storage.getEmpId()!!,
        createdAt: Date.now()
      }
      applicationId.value =
        new Date().getFullYear().toString() +
        String(new Date().getMonth() + 1).padStart(2, '0') +
        String(new Date().getDate()).padStart(2, '0') +
        Math.floor(1000 + Math.random() * 9000)
      const request: ApplyLoanRequest = {
        applicationId: applicationId.value,
        customerId: Number(customer.value?.customer?.customerId),
        loanType: selectedScheme.value?.loanType,
        loanAmount: ConversionUtility.toPaiseConversion(loanAmount.value),
        interestRate: loanInterestRate.value,
        tenureMonths: loanTenure.value,
        incomePerAnnum: ConversionUtility.toPaiseConversion(incomePerAnnum.value),
        incomePerMonth: ConversionUtility.toPaiseConversion(incomePerMonth.value ?? 0),
        employerName: employerName.value ?? null,
        businessDetails: businessDetails.value ?? null,
        agricultureDetails: agricultureDetails.value ?? null,
        emiAmount: Number(emiSchedule.value[0].emi.toFixed(0)),
        interestAmount: ConversionUtility.toPaiseConversion(
          Number(interestAmount.value.toFixed(0))
        ),
        shareTransferPercentage: sharePercent.value,
        shareHolderTransferAmount: shareholderTransferAmount.value!!,
        processingFees: ConversionUtility.toPaiseConversion(processingFees.value),
        amountToTransferToSB: ConversionUtility.toPaiseConversion(sbTransferAmount.value!!),
        collateralDetails: collateralDetails.value,
        branchCode: Storage.getEmpBranchCode()!!,
        documentSubmitted: documentSubmitted.value,
        createdAt: loanStartDate.value.getTime(),
        guarantors: [
          Number(guarantor1.value?.customer?.customerId),
          Number(guarantor2.value?.customer?.customerId)
        ],
        createdBy: createBy,
        emiDate: emiDate.value!,
        shareFees: ConversionUtility.toPaiseConversion(shareFee.value!!),
        miscFees: ConversionUtility.toPaiseConversion(miscellaneousFees.value!!),
        buildingFees: ConversionUtility.toPaiseConversion(buildingFees.value!!),
        directorId: Number(director.value?.customer?.customerId ?? null)
      }
      applyLoanRequest.value = request
      ApiService.applyLoan(request)
        .then((response) => {
          if (response.statusCode == 200) {
            isApplyLoanVisible.value = false
            loanCreatedSuccessfully.value = true
          } else {
            showToast('Failed to apply loan', response.error ?? 'No error response!', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to apply loan', error, 'error')
        })
        .finally(() => {
          isLoading.value = false
        })
    },
    reject() {}
  })
}

function clear() {
  activeStep.value = '1'
  selectedScheme.value = undefined
  loanInterestRate.value = undefined
  LoanSchemes.value = []
  loanAmount.value = undefined
  loanTenure.value = undefined
  incomePerAnnum.value = undefined
  employerName.value = undefined
  businessDetails.value = undefined
  agricultureDetails.value = undefined
  incomePerMonth.value = undefined
  professionType.value = professionTypes.value[0]
  processingFees.value = undefined
  buildingFees.value = undefined
  miscellaneousFees.value = undefined
  sharePercent.value = undefined
  totalLoanAmount.value = undefined
  sbTransferAmount.value = undefined
  totalLoanPayableAmount.value = undefined
  emiSchedule.value = []
  collateralDetails.value = undefined
  purpose.value = undefined
  documentSubmitted.value = []
  displayDocuments.value = []
  guarantor1.value = undefined
  guarantor2.value = undefined
  showEmiSchedule.value = false
  loanCreatedSuccessfully.value = false
  emiDate.value = undefined
  interestAmount.value = undefined
  shareFee.value = undefined
  shareholderTransferAmount.value = undefined
  director.value = undefined
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}

function printDiv() {
  const element = document.getElementById('printable-content') as HTMLElement

  if (!element) {
    console.error('Element not found!')
    return
  }

  // Create a new window for the print preview
  const printWindow = window.open('', '', 'height=600,width=800')

  if (printWindow) {
    // Get all the styles from the current document
    const tailwindCSSLink = Array.from(document.querySelectorAll('link[rel="stylesheet"]'))
      .map((link) => link.outerHTML)
      .join('')

    // Write the content to the new window, along with styles
    printWindow.document.write('<html><head><title>Print Div</title>')
    printWindow.document.write(tailwindCSSLink) // Inject CSS links
    printWindow.document.write('</head><body>')
    printWindow.document.write(element.outerHTML) // Add the content
    printWindow.document.write('</body></html>')

    // Close the document to finish rendering
    printWindow.document.close()

    // Open the print dialog
    printWindow.print() // Trigger the print
    printWindow.onafterprint = () => printWindow.close()
  }
}
</script>
<template>
  <main class="main">
    <Toast class="error-p-toast" />
    <Card class="search-card">
      <template #content>
        <ConfirmDialog :draggable="false" :closable="false" />
        <SearchComponent
          title="Apply for a Loan"
          subtitle="Search for a Customer using Customer ID. (eg - 100001)"
          placeholder="Search Customer"
          @search-click="handelSearchCustomer"
          @enter-press="handelSearchCustomer"
        />
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <div v-if="!customer && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No accounts found for the provided search query!" style="font-weight: 600" />
        </div>
        <div v-if="customer?.shareholder" class="cont">
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer ID: </span>
              <span class="text">{{ customer?.customer?.customerId }}</span>
            </div>
            <div>
              <span class="text-heading">Customer Name: </span>
              <span class="text"
                >{{ customer?.customer?.firstName }} {{ customer?.customer?.middleName ?? '' }}
                {{ customer?.customer?.lastName }}</span
              >
            </div>
            <div>
              <span class="text-heading">Shareholder Amount: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(customer?.shareholder?.shareAmount)
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Balance: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(customer?.sbAccount?.balance!!)
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(customer?.customer?.createdAt!!) }}<br
              /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <Tag severity="secondary"
                >Apply for loan after checking for eligibility of the candidate.</Tag
              >
            </div>
            <div>
              <CustomButton
                title="Apply for Loan"
                icon="arrow-right"
                @click="
                  () => {
                    clear()
                    getLoanSchemes()
                  }
                "
              />
            </div>
          </div>
        </div>
        <div v-if="customer && !customer?.shareholder">
          <Tag severity="secondary"
            >No shareholder accounts found for the provided search query!</Tag
          >
        </div>
        <Dialog
          v-model:visible="isApplyLoanVisible"
          modal
          header="Apply for Loan"
          :draggable="false"
        >
          <Stepper :value="activeStep" linear>
            <StepList value="">
              <Step value="1">Select a Scheme</Step>
              <Step value="2">Customer Details</Step>
              <Step value="3">Loan Details</Step>
              <Step value="4">Guarantor Details</Step>
            </StepList>
            <StepPanels value="">
              <StepPanel value="1">
                <div class="dialog-container">
                  <Fieldset legend="Loan Schemes" class="schemes">
                    <div class="next-tag center-tag">
                      <Tag severity="secondary"
                        >Once you select a scheme you will be redirected to the next step.</Tag
                      >
                    </div>
                    <Divider />
                    <Panel>
                      <DataTable
                        :value="LoanSchemes"
                        v-model:selection="selectedScheme"
                        @row-select="
                          () => {
                            activeStep = '2'
                            loanInterestRate = DateUtils.isSeniorCitizen(
                              customer?.customer?.dateOfBirth!!
                            )
                              ? selectedScheme?.scInterestRates
                              : selectedScheme?.generalInterestRates
                            onProfessionSelectChange()
                          }
                        "
                        dataKey="id"
                        tableStyle="min-width: 50rem"
                      >
                        <Column selectionMode="single"></Column>
                        <Column field="loanType" header="Scheme Name" />
                        <Column field="generalInterestRates" header="General Interest Rate">
                          <template #body="slotProps">
                            {{ slotProps.data.generalInterestRates ?? '-' }} %
                          </template>
                        </Column>
                        <Column field="scInterestRates" header="Senior Citizen Interest Rate">
                          <template #body="slotProps">
                            {{ slotProps.data.scInterestRates ?? '-' }} %
                          </template>
                        </Column>
                      </DataTable>
                    </Panel>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="2">
                <div class="dialog-container">
                  <Fieldset legend="Customer Details">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Customer Name: </span>
                        <span class="text"
                          >{{ customer?.customer?.firstName }}
                          {{ customer?.customer?.middleName ?? '' }}
                          {{ customer?.customer?.lastName }}</span
                        >
                      </div>
                      <div>
                        <span class="text-heading">Balance: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(customer?.sbAccount?.balance!!)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Shareholder Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(
                            customer?.shareholder?.shareAmount!!
                          )
                        }}</span>
                      </div>
                    </div>
                    <Divider />
                    <Panel header="Profession Details">
                      <div class="profession-type-selection">
                        <SelectButton
                          :options="professionTypes"
                          option-label="name"
                          v-model="professionType"
                          :allow-empty="false"
                          v-on:change="onProfessionSelectChange"
                        ></SelectButton>
                      </div>
                      <Divider />
                      <div class="form">
                        <FloatLabel v-if="professionType.name == 'Employed'">
                          <InputText v-model="employerName" id="employerName" class="input-text" />
                          <label for="employerName" class="mandatory">Employer Name</label>
                        </FloatLabel>
                        <FloatLabel v-if="professionType.name == 'Business'">
                          <InputText
                            v-model="businessDetails"
                            id="businessDetails"
                            class="input-text"
                          />
                          <label for="businessDetails" class="mandatory">Business Details</label>
                        </FloatLabel>
                        <FloatLabel v-if="professionType.name == 'Agriculture'">
                          <InputText
                            v-model="agricultureDetails"
                            id="agricultureDetails"
                            class="input-text"
                          />
                          <label for="agricultureDetails" class="mandatory"
                            >Agriculture Details</label
                          >
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="incomePerAnnum"
                            id="incomePerAnnum"
                            class="input-text"
                            locale="EN-IN"
                            prefix="₹ "
                            :min="0"
                            :max-fraction-digits="0"
                          />
                          <label for="incomePerAnnum" class="mandatory">Income Per Annum</label>
                        </FloatLabel>
                        <FloatLabel v-if="professionType.name == 'Employed'">
                          <InputNumber
                            v-model="incomePerMonth"
                            id="incomePerMonth"
                            class="input-text"
                            locale="EN-IN"
                            prefix="₹ "
                            :max-fraction-digits="0"
                            :min="0"
                          />
                          <label for="incomePerMonth" class="mandatory">Income Per Month</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputText
                            v-model="collateralDetails"
                            id="collateralDetails"
                            class="input-text"
                          />
                          <label for="collateralDetails" class="mandatory"
                            >Collateral Details</label
                          >
                        </FloatLabel>
                        <FloatLabel>
                          <MultiSelect
                            :maxSelectedLabels="2"
                            v-model="documentSubmitted"
                            display="chip"
                            class="input-text"
                            filter
                            :options="displayDocuments"
                            optionValue="name"
                            optionLabel="name"
                          ></MultiSelect>
                          <label for="documentSubmitted" class="mandatory"
                            >Document Submitted</label
                          >
                        </FloatLabel>
                      </div>
                      <template #footer>
                        <Divider />
                        <div
                          style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                          "
                        >
                          <span class="note"
                            >Note: Provide either employer name / business details / agriculture
                            details</span
                          >
                          <CustomButton title="next" icon="arrow-right" @click="validateStep2" />
                        </div>
                      </template>
                    </Panel>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="3">
                <div class="dialog-container">
                  <Fieldset legend="Loan Details">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Loan Type: </span>
                        <span class="text">{{ selectedScheme?.loanType }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Interest Rate: </span>
                        <span class="text">{{ loanInterestRate }} %</span>
                      </div>
                    </div>
                    <Divider />
                    <Panel header="Loan Calculations">
                      <Divider style="margin-top: 0" />
                      <div class="form">
                        <FloatLabel>
                          <InputNumber
                            v-model="loanAmount"
                            id="loanAmount"
                            class="input-text"
                            locale="en-IN"
                            prefix="₹ "
                            :max-fraction-digits="0"
                            :min="0"
                          />
                          <label for="loanAmount" class="mandatory">Principal Amount</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="loanTenure"
                            id="loanTenure"
                            :suffix="loanTenure == 1 ? ' Month' : ' Months'"
                            class="input-text"
                            :max-fraction-digits="0"
                            :min="0"
                          />
                          <label for="loanTenure" class="mandatory">Loan Tenure</label>
                        </FloatLabel>
                        <!-- <FloatLabel>
                          <DatePicker
                            v-model:model-value="loanStartDate"
                            :min-date="minDate"
                            :max-date="maxDate"
                            class="input-test w-full"
                            id="loanStartDate"
                            date-format="dd/mm/yy"
                          ></DatePicker>
                          <label for="loanStartDate" class="mandatory">Loan Start Date</label>
                        </FloatLabel> -->
                        <FloatLabel>
                          <InputNumber
                            v-model="emiDate"
                            id="emiDate"
                            :suffix="
                              emiDate == 1
                                ? ' st'
                                : emiDate == 2
                                  ? ' nd'
                                  : emiDate == 3
                                    ? ' rd'
                                    : ' th'
                            "
                            class="input-text"
                            :max-fraction-digits="0"
                            :min="1"
                            :max="15"
                          />
                          <label for="emiDate" class="mandatory">EMI Date</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="processingFees"
                            id="processingFees"
                            class="input-text"
                            locale="en-IN"
                            prefix="₹ "
                            :min="0"
                            :max-fraction-digits="2"
                          />
                          <label for="processingFees" class="mandatory">Processing Fees</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="buildingFees"
                            id="buildingFees"
                            class="input-text"
                            locale="en-IN"
                            prefix="₹ "
                            :min="0"
                            :max-fraction-digits="2"
                          />
                          <label for="buildingFees" class="mandatory">Building Fees</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="miscellaneousFees"
                            id="miscellaneousFees"
                            class="input-text"
                            locale="en-IN"
                            prefix="₹ "
                            :min="0"
                            :max-fraction-digits="2"
                          />
                          <label for="miscellaneousFees" class="mandatory"
                            >Miscellaneous Fees</label
                          >
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="sharePercent"
                            id="sharePercent"
                            suffix=" %"
                            :min="0"
                            :max="40"
                            class="input-text"
                          />
                          <label for="sharePercent">Share Percent</label>
                        </FloatLabel>
                        <FloatLabel>
                          <InputNumber
                            v-model="shareFee"
                            id="shareFee"
                            class="input-text"
                            locale="en-IN"
                            prefix="₹ "
                            :min="0"
                            :max-fraction-digits="2"
                            :disabled="!shareFee"
                          />
                          <label for="shareFee" class="mandatory">Share Fees</label>
                        </FloatLabel>
                      </div>
                      <Slider v-model="sharePercent" :min="0" :max="40" style="margin: 2rem" />
                      <span class="note" style="margin-bottom: 1rem"
                        >Note : Press enter after entering Loan Details.</span
                      >
                      <div class="holder-details">
                        <div>
                          <span class="text-heading">Principal Amount: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(loanAmount ?? 0)
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Processing Fees: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(processingFees ?? 0)
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Building Fees: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(buildingFees ?? 0)
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Miscellaneous Fees: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(miscellaneousFees ?? 0)
                          }}</span>
                        </div>
                      </div>
                      <div class="holder-details">
                        <div>
                          <span class="text-heading">Existing Shareholder Amount: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupeesConversion(
                              customer?.shareholder?.shareAmount ?? 0
                            )
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Share Amount for loan: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupeesConversion(
                              (sharePercent ?? 0 / 100) * (loanAmount ?? 0)
                            )
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Shareholder Transfer Amount: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupeesConversion(
                              shareholderTransferAmount ?? 0
                            )
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Share Fee: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(shareFee ?? 0)
                          }}</span>
                        </div>
                      </div>
                      <div class="holder-details">
                        <div>
                          <span class="text-heading">Loan Tenure: </span>
                          <span class="text">{{
                            loanTenure
                              ? new Date(
                                  DateUtils.getFutureDateInMilliseconds(
                                    emiDate!!,
                                    loanTenure + 1,
                                    loanStartDate
                                  )
                                ).toDateString()
                              : '-'
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Total Interest Amount: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(interestAmount ?? 0)
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">SB Transfer Amount: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(sbTransferAmount ?? 0)
                          }}</span>
                        </div>
                        <div>
                          <span class="text-heading">Total Amount Payable: </span>
                          <span class="text">{{
                            ConversionUtility.toStringRupees(totalLoanPayableAmount ?? 0)
                          }}</span>
                        </div>
                      </div>
                      <Divider />
                      <div class="operations">
                        <span class="note">Note: Calculate again when you change any value.</span>
                        <CustomButton
                          title="Calculate"
                          icon="calculator"
                          @click="
                            () => {
                              emiSchedule = []
                              emiSchedule = InterestAndTotalCalculator.generateAmortizationSchedule(
                                ConversionUtility.toPaiseConversion(loanAmount!!),
                                loanInterestRate!!,
                                loanTenure!!
                              )
                              calculateTotalLoanPayableAmount()
                            }
                          "
                        />
                      </div>
                      <template #footer>
                        <Divider style="margin-top: 0" />
                        <div class="end-button-container">
                          <div style="display: flex; gap: 1rem">
                            <CustomButton
                              title="See EMI Schedule"
                              icon="calendar"
                              :disabled="!totalLoanPayableAmount"
                              @click="
                                () => {
                                  showEmiSchedule = !showEmiSchedule
                                }
                              "
                            />
                            <CustomButton
                              title="Next"
                              :disabled="!sbTransferAmount"
                              icon="arrow-right"
                              @click="validateStep3"
                            />
                          </div>
                        </div>
                      </template>
                    </Panel>
                    <Panel header="EMI Schedule" v-if="showEmiSchedule" style="margin-top: 1rem">
                      <Divider style="margin-top: 0" />
                      <DataTable :value="emiSchedule">
                        <Column field="month" header="Month"></Column>
                        <Column header="Date">
                          <template #body="slotProps">
                            {{
                              ConversionUtility.toDateFormat(
                                DateUtils.getFutureDateInMilliseconds(
                                  emiDate!!,
                                  slotProps.data.month + 1,
                                  loanStartDate
                                )
                              )
                            }}
                          </template>
                        </Column>
                        <Column field="emi" header="EMI">
                          <template #body="slotProps">
                            {{ ConversionUtility.toStringRupeesConversion(slotProps.data.emi) }}
                          </template>
                        </Column>
                        <Column field="principal" header="Principal">
                          <template #body="slotProps">
                            {{
                              ConversionUtility.toStringRupeesConversion(slotProps.data.principal)
                            }}
                          </template>
                        </Column>
                        <Column field="interest" header="Interest"
                          >\
                          <template #body="slotProps">
                            {{
                              ConversionUtility.toStringRupeesConversion(slotProps.data.interest)
                            }}
                          </template>
                        </Column>
                        <Column
                          field="outstandingPrincipal"
                          style="width: 30%"
                          header="Outstanding Principal"
                        >
                          <template #body="slotProps">
                            {{
                              ConversionUtility.toStringRupeesConversion(
                                Math.abs(slotProps.data.outstandingPrincipal)
                              )
                            }}
                          </template>
                        </Column>
                      </DataTable>
                    </Panel>
                    <div></div>
                  </Fieldset>
                </div>
              </StepPanel>
              <StepPanel value="4">
                <div class="dialog-container">
                  <Fieldset legend="Select Guarantors">
                    <div class="guarantor-container">
                      <Panel header="Guarantor 1" class="guarantor">
                        <SearchComponent
                          title="Search Guarantor"
                          placeholder="Search with Customer ID."
                          subtitle="Search for a Guarantor using Customer ID."
                          @search-click="handelGuarantor1Search"
                          @enter-press="handelGuarantor1Search"
                          v-if="!guarantor1"
                        />
                        <div v-if="guarantor1" class="guarantor">
                          <div>
                            <span class="text-heading">Name: </span>
                            <span class="text"
                              >{{ guarantor1?.customer?.firstName }}
                              {{ guarantor1?.customer?.middleName ?? '' }}
                              {{ guarantor1?.customer?.lastName }}</span
                            >
                          </div>
                          <div>
                            <span class="text-heading">Shareholder Amount: </span>
                            <span class="text">{{
                              ConversionUtility.toStringRupeesConversion(
                                guarantor1?.shareholder?.shareAmount!!
                              )
                            }}</span>
                          </div>
                          <div>
                            <span class="text-heading">Phone Number: </span>
                            <span class="text">{{ guarantor1?.customer?.phoneNumber }}</span>
                          </div>
                          <CustomButton title="Clear" @click="guarantor1 = undefined" />
                        </div>
                      </Panel>
                      <Panel header="Guarantor 2" class="guarantor">
                        <SearchComponent
                          title="Search Guarantor"
                          placeholder="Search with Customer ID."
                          subtitle="Search for a Guarantor using Customer ID."
                          @search-click="handelGuarantor2Search"
                          @enter-press="handelGuarantor2Search"
                          v-if="!guarantor2"
                        />
                        <div v-if="guarantor2" class="guarantor">
                          <div>
                            <span class="text-heading">Name: </span>
                            <span class="text"
                              >{{ guarantor2?.customer?.firstName }}
                              {{ guarantor2?.customer?.middleName ?? '' }}
                              {{ guarantor2?.customer?.lastName }}</span
                            >
                          </div>
                          <div>
                            <span class="text-heading">Shareholder Amount: </span>
                            <span class="text">{{
                              ConversionUtility.toStringRupeesConversion(
                                guarantor2?.shareholder?.shareAmount!!
                              )
                            }}</span>
                          </div>
                          <div>
                            <span class="text-heading">Phone Number: </span>
                            <span class="text">{{ guarantor2?.customer?.phoneNumber }}</span>
                          </div>
                          <CustomButton title="Clear" @click="guarantor2 = undefined" />
                        </div>
                      </Panel>
                    </div>
                  </Fieldset>
                  <Fieldset legend="Recommendation">
                    <Panel header="Optional" class="guarantor">
                      <SearchComponent
                        title="Search Director"
                        placeholder="Search with Customer ID."
                        subtitle="Search for a Director using Customer ID."
                        @search-click="handelDirectorSearch"
                        @enter-press="handelDirectorSearch"
                        v-if="!director"
                      />
                      <div v-if="director" class="guarantor">
                        <div>
                          <span class="text-heading">Name: </span>
                          <span class="text"
                            >{{ director?.customer?.firstName }}
                            {{ director?.customer?.middleName ?? '' }}
                            {{ director?.customer?.lastName }}</span
                          >
                        </div>
                        <div>
                          <span class="text-heading">Phone Number: </span>
                          <span class="text">{{ director?.customer?.phoneNumber }}</span>
                        </div>
                        <CustomButton title="Clear" @click="director = undefined" />
                      </div>
                    </Panel>
                  </Fieldset>
                  <Fieldset legend="Confirm Loan Details">
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Customer Name: </span>
                        <span class="text">{{
                          customer?.customer?.firstName +
                          ' ' +
                          (customer?.customer?.middleName ?? '') +
                          ' ' +
                          customer?.customer?.lastName
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Balance: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(customer?.sbAccount?.balance!!)
                        }}</span>
                      </div>
                    </div>
                    <Divider style="margin-top: 0" />
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Loan Type: </span>
                        <span class="text">{{ selectedScheme?.loanType }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Interest Rate: </span>
                        <span class="text">{{ loanInterestRate }} %</span>
                      </div>
                      <div>
                        <span class="text-heading">Loan Tenure: </span>
                        <span class="text" v-if="emiDate">{{
                          loanTenure
                            ? new Date(
                                DateUtils.getFutureDateInMilliseconds(
                                  emiDate,
                                  loanTenure + 1,
                                  loanStartDate
                                )
                              ).toDateString()
                            : '-'
                        }}</span>
                      </div>
                    </div>
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Principal Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(loanAmount!!)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Processing Fees: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(processingFees!!)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Building Fees: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(buildingFees ?? 0)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Miscellaneous Fees: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(miscellaneousFees ?? 0)
                        }}</span>
                      </div>
                    </div>
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Existing Shareholder Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(
                            customer?.shareholder?.shareAmount ?? 0
                          )
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Share Amount for loan: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(
                            (sharePercent ?? 0 / 100) * (loanAmount ?? 0)
                          )
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Shareholder Transfer Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupeesConversion(shareholderTransferAmount ?? 0)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Share Fee: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(shareFee ?? 0)
                        }}</span>
                      </div>
                    </div>
                    <div class="holder-details">
                      <div>
                        <span class="text-heading">Shareholder Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(
                            sharePercent ? (sharePercent / 100) * (loanAmount ?? 0) : 0
                          )
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">SB Transfer Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(sbTransferAmount ?? 0)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Total Interest Amount: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(interestAmount!!)
                        }}</span>
                      </div>
                      <div>
                        <span class="text-heading">Total Amount Payable: </span>
                        <span class="text">{{
                          ConversionUtility.toStringRupees(totalLoanPayableAmount ?? 0)
                        }}</span>
                      </div>
                    </div>
                    <div class="operations">
                      <Tag severity="secondary"
                        >Please ensure all documents are thoroughly reviewed and eligibility is
                        confirmed before proceeding to apply.</Tag
                      >
                      <CustomButton
                        title="Apply for loan"
                        icon="arrow-right"
                        :disabled="!guarantor1 || !guarantor2"
                        @click="applyLoan"
                      ></CustomButton>
                    </div>
                  </Fieldset>
                </div>
              </StepPanel>
            </StepPanels>
          </Stepper>
        </Dialog>
        <Dialog
          v-model:visible="loanCreatedSuccessfully"
          modal
          :draggable="false"
          class="w-[95vw]"
          v-on:after-hide="clear()"
        >
          <template #header>
            <span class="text-2xl text-green-700 font-semibold"
              >Loan Application Created Successfully</span
            >
          </template>
          <Divider style="margin-top: 0" />
          <div class="loan-approve-container gap-5">
            <div class="holder-details px-10 border border-slate-200 rounded-md">
              <div>
                <span class="text-heading">Application ID: </span>
                <span class="text">{{ applicationId }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Customer ID: </span>
                <span class="text">{{ customer?.customer?.customerId }}<br /></span>
              </div>
              <div>
                <span class="text-heading">Customer Name: </span>
                <span class="text"
                  >{{
                    customer?.customer?.firstName +
                    ' ' +
                    (customer?.customer?.middleName ?? '') +
                    ' ' +
                    customer?.customer?.lastName
                  }}<br
                /></span>
              </div>
              <div>
                <span class="text-heading">Type: </span>
                <span class="text">{{ selectedScheme?.loanType }}<br /></span>
              </div>
            </div>
            <Panel header="Loan Details">
              <Divider style="margin-top: 0" />
              <div class="selectLoanSection">
                <div>
                  <span class="text-heading">Tenure(Months): </span>
                  <span class="text">{{ loanTenure }} Months<br /></span>
                </div>
                <div>
                  <span class="text-heading">Tenure(years): </span>
                  <span class="text" v-if="loanTenure"
                    >{{ (loanTenure / 12).toFixed(1) }} Years<br />
                  </span>
                </div>
                <div>
                  <span class="text-heading">Loan End Date: </span>
                  <span class="text" v-if="emiDate">{{
                    loanTenure
                      ? new Date(
                          DateUtils.getFutureDateInMilliseconds(
                            emiDate,
                            loanTenure + 1,
                            loanStartDate
                          )
                        ).toDateString()
                      : '-'
                  }}</span>
                  <span v-else class="text">-</span>
                </div>
                <div>
                  <span class="text-heading">Interest Rate: </span>
                  <span class="text">{{ loanInterestRate }} %<br /></span>
                </div>
                <div>
                  <span class="text-heading">Branch Code: </span>
                  <span class="text">{{ Storage.getEmpBranchCode() }}<br /></span>
                </div>
                <div>
                  <span class="text-heading">Created On: </span>
                  <span class="text"
                    >{{ ConversionUtility.toDateAndTimeFormat(new Date().getTime()) }}<br
                  /></span>
                </div>
                <div>
                  <span class="text-heading">Created By: </span>
                  <span class="text">{{ Storage.getEmpId() }}<br /></span>
                </div>
                <div>
                  <span class="text-heading">EMI Date: </span>
                  <span class="text"
                    >{{ emiDate
                    }}{{ emiDate == 1 ? ' st' : emiDate == 2 ? ' nd' : emiDate == 3 ? ' rd' : ' th'
                    }}<br
                  /></span>
                </div>
                <div>
                  <span class="text-heading">Existing Shareholder Amount: </span>
                  <span class="text">{{
                    ConversionUtility.toStringRupeesConversion(
                      customer?.shareholder?.shareAmount ?? 0
                    )
                  }}</span>
                </div>
                <div>
                  <span class="text-heading">Share Amount for loan: </span>
                  <span class="text">{{
                    ConversionUtility.toStringRupeesConversion(
                      (sharePercent ?? 0 / 100) * (loanAmount ?? 0)
                    )
                  }}</span>
                </div>
                <div>
                  <span class="text-heading">Shareholder Transfer Amount: </span>
                  <span class="text">{{
                    ConversionUtility.toStringRupeesConversion(
                      sharePercent
                        ? ConversionUtility.toPaiseConversion(
                            (sharePercent / 100) * (loanAmount ?? 0)
                          ) -
                            customer?.shareholder?.shareAmount!! >
                          0
                          ? ConversionUtility.toPaiseConversion(
                              (sharePercent / 100) * (loanAmount ?? 0)
                            ) - customer?.shareholder?.shareAmount!!
                          : 0
                        : 0
                    )
                  }}</span>
                </div>
                <div>
                  <span class="text-heading">Share Fee: </span>
                  <span class="text">{{ ConversionUtility.toStringRupees(shareFee ?? 0) }}</span>
                </div>
                <div style="display: flex; flex-direction: column">
                  <span class="text-heading">Guarantors: </span>
                  <span class="text chip-container"
                    ><Chip>{{ guarantor1?.customer?.customerId }}</Chip
                    ><Chip>{{ guarantor2?.customer?.customerId }}</Chip></span
                  >
                </div>
              </div>
            </Panel>
            <Panel header="Amount Details">
              <Divider style="margin-top: 0" />
              <div class="flex flex-col gap-5">
                <Panel class="flex flex-col">
                  <span class="text-base flex">Total Deductions :</span>
                  <Divider />
                  <div class="calculation-container">
                    <span class="text-heading">Principal Amount: </span>
                    <span class="text" v-if="loanAmount"
                      >{{ ConversionUtility.toStringRupees(loanAmount) }}<br
                    /></span>
                    <span v-else class="text">-</span>
                  </div>
                  <div class="calculation-container">
                    <span class="text-heading"
                      >Shareholder Transfer Amount @ ({{ sharePercent }} %)
                    </span>
                    <span class="text" v-if="sharePercent && loanAmount"
                      >- {{ ConversionUtility.toStringRupeesConversion(shareholderTransferAmount!!)
                      }}<br
                    /></span>
                    <span v-else class="text">-</span>
                  </div>
                  <div class="calculation-container">
                    <span class="text-heading">Processing Fees: </span>
                    <span class="text" v-if="processingFees"
                      >- {{ ConversionUtility.toStringRupees(processingFees) }}<br
                    /></span>
                    <span v-else class="text">-</span>
                  </div>
                  <div class="calculation-container">
                    <span class="text-heading">Building Fees: </span>
                    <span class="text" v-if="buildingFees"
                      >- {{ ConversionUtility.toStringRupees(buildingFees) }}</span
                    >
                    <span v-else class="text">-</span>
                  </div>
                  <div class="calculation-container">
                    <span class="text-heading">Miscellaneous Fees: </span>
                    <span class="text" v-if="miscellaneousFees"
                      >- {{ ConversionUtility.toStringRupees(miscellaneousFees ?? 0) }}</span
                    >
                    <span v-else class="text">-</span>
                  </div>
                  <Divider />
                  <div class="calculation-container">
                    <span class="text-heading">Transfer To SB: </span>
                    <span class="text" v-if="sbTransferAmount"
                      >{{ ConversionUtility.toStringRupees(sbTransferAmount) }}<br
                    /></span>
                    <span v-else class="text">-</span>
                  </div>
                </Panel>
                <Panel class="flex flex-col">
                  <span class="text-base flex">Total Loan Amount:</span>
                  <Divider />
                  <div class="calculation-container">
                    <span class="text-heading">Principal Amount (A): </span>
                    <span class="text" v-if="loanAmount"
                      >{{ ConversionUtility.toStringRupees(loanAmount) }}<br
                    /></span>
                    <span v-else class="text">-</span>
                  </div>
                  <div class="calculation-container">
                    <span class="text-heading"
                      >Total Interest Amount @ {{ loanInterestRate }} % (B):
                    </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupees(interestAmount!!) }}<br
                    /></span>
                  </div>
                  <Divider />
                  <div class="calculation-container">
                    <span class="text-heading">Total Payable Amount (A + B): </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupees(totalLoanPayableAmount!!) }}<br
                    /></span>
                  </div>
                </Panel>
              </div>
            </Panel>
            <Panel header="Customer Details">
              <Divider style="margin-top: 0" />
              <div class="selectLoanSection">
                <div>
                  <span class="text-heading">Customer ID: </span>
                  <span class="text">{{ customer?.customer?.customerId }}<br /></span>
                </div>
                <div>
                  <span class="text-heading"
                    >{{
                      employerName
                        ? `Employer Name`
                        : businessDetails
                          ? `Business Details`
                          : agricultureDetails
                            ? `Agriculture Details`
                            : `Something Went Wrong`
                    }}:
                  </span>
                  <span class="text"
                    >{{ employerName ?? businessDetails ?? agricultureDetails }}<br
                  /></span>
                </div>
                <div>
                  <span class="text-heading">Income Per Annum: </span>
                  <span class="text"
                    >{{ ConversionUtility.toStringRupees(incomePerAnnum!!) }}<br
                  /></span>
                </div>
                <div v-if="incomePerMonth">
                  <span class="text-heading">Income Per Month: </span>
                  <span class="text"
                    >{{ ConversionUtility.toStringRupees(incomePerMonth) }}<br
                  /></span>
                </div>
                <div>
                  <span class="text-heading">Collateral Details: </span>
                  <span class="text">{{ collateralDetails }}<br /></span>
                </div>
                <div style="display: flex; flex-direction: column">
                  <span class="text-heading">Documents Submitted: </span>
                  <span
                    class="text chip-container"
                    style="flex-direction: column; justify-content: start; align-items: start"
                    ><Chip v-for="doc in documentSubmitted" style="text-align: center">{{
                      doc
                    }}</Chip
                    ><br
                  /></span>
                </div>
              </div>
            </Panel>
            <div class="operations">
              <div class="end-button-container">
                <CustomButton
                  title="Print Customer Copy"
                  icon="print"
                  @click="PDFGenerator.loanApplication(true, applyLoanRequest!!, customer!!)"
                />
                <CustomButton
                  title="Print Bank Copy"
                  icon="print"
                  @click="PDFGenerator.loanApplication(false, applyLoanRequest!!, customer!!)"
                />
              </div>
            </div>
          </div>
        </Dialog>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

h2 {
  margin: 0.5rem;
  text-align: start;
}

.form {
  margin: 1.8rem 0 0;
  display: grid;
  gap: 1.8rem;
  grid-template-columns: 1fr 50%;
}

.end-button-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.dialog-container {
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.holder-details {
  padding-top: 0.75rem;
}

.schemes {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
  flex-direction: column;
  gap: 1rem;

  .next-tag {
    margin: 0 0 1rem 0;
  }
}

.loan-approve-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.center-tag {
  display: flex;
  justify-content: center;
}

.guarantor-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  .guarantor {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.profession-type-selection {
  width: 100%;
  display: flex;
  justify-content: center;
}
.operations {
  align-items: center;
}
.calculation-container {
  display: flex;
  justify-content: space-between;
}
.selectLoanSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  row-gap: 1.5rem;
  column-gap: 1rem;
}
.chip-container {
  display: flex;
  gap: 0.5rem;
}
</style>
