<script setup lang="ts">
</script>
<template>
    <main class="background-container">
        <Card class="warning-card">
            <template #header>
                <h2 class="text-3xl font-bold">Invalid Device!!</h2>
                <Divider/>
            </template>
            <template #subtitle>
                Kindly open the application in a laptop or a desktop.
            </template>
        </Card>
        <div class="bottom-center">Powered by <b>Matrex Softwares</b></div>
    </main>
</template>
<style scoped>
.background-container {
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottom-center {
    position: absolute;
    bottom: 20px;
    color: #000000;
    font-size: 16px;
    font-weight: 300;
}
.warning-card{
    width: 40vw;
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 650px) {
  .warning-card{
    width: 80vw;
  }
}
</style>