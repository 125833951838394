
import type { DtrumApi, PropertyMap } from '@dynatrace/dtrum-api-types'

export class DynatraceManager {

  public static identifyUser(userTag: string) {
    if (window.dtrum) {
      return window.dtrum.identifyUser(userTag)
    }
    console.log("Dynatrace RUM is not available.")
  }

  public static endSession() {
    if (window.dtrum) {
      return window.dtrum.endSession()
    }
    console.log("Dynatrace RUM is not available.")
  }

  public static reportError(error: string) {
    if (window.dtrum) {
      return window.dtrum.reportError(error)
    }
    console.log("Dynatrace RUM is not available.")
  }

  public static sendStringSessionProperties(properties: PropertyMap<string>) {
    if (window.dtrum) {
      return window.dtrum.sendSessionProperties(undefined, undefined, properties, undefined)
    }
    console.log("Dynatrace RUM is not available.")
  }

  public static sendNumberSessionProperties(properties: PropertyMap<number>) {
    if (window.dtrum) {
      return window.dtrum.sendSessionProperties(properties)
    }
    console.log("Dynatrace RUM is not available.")
  }
}