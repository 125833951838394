<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import type { SearchCustomerRequest } from '@/api/models/customer/SearchCustomerRequest'
import type { Customer } from '@/api/models/customer/Customer'
import ConversionUtility from '@/utils/ConversionUtility'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { CreatedBy } from '@/api/models/CreatedBy'
import LoadingComponent from '@/components/LoadingComponent.vue'
import ValidationUtility from '@/utils/ValidationUtility'

const customer = ref<Customer[] | null>()
const fdAmount = ref<number>()
const fdTerm = ref<number>() // FD-specific field for deposit term
const toast = useToast()
const isLoading = ref<boolean>(false)
const fixedDeposit = undefined
const searchQuery = ref<string>('')

const fdTableColumns = [
  { field: 'id', header: 'FD ID', colStyle: { width: '25%' } },
  { field: 'fdAmount', header: 'FD Amount(₹)', colStyle: { width: '30%' } },
  { field: 'maturityDate', header: 'Maturity Date', colStyle: { width: '30%' } },
  { field: 'createdAt', header: 'Created On:', colStyle: { width: '15%' } }
]

function handleDataTableProps(index: number, data: any): string {
  switch (index) {
    case 0: {
      return data.id
    }
    case 1: {
      return ConversionUtility.toStringRupeesConversion(data.fdAmount)
    }
    case 2: {
      return ConversionUtility.toDateFormat(data.maturityDate)
    }
    case 3: {
      return ConversionUtility.toDateFormat(data.createdAt)
    }
    default: {
      return 'NA'
    }
  }
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>


<template>
    <main class="main">
      <Card class="search-card">
        <template #content>
          <Toast class="error-p-toast" />
          <SearchComponent
            title="Create Fixed Deposit"
            subtitle="Create an FD for an existing customer. (Eg. - 100001)"
            placeholder="Enter the customer ID to create FD"
          />
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
          <div v-if="customer && !isLoading" class="cont">
            <div class="holder-details">
              <!-- Customer details similar to Pigmy -->
            </div>
            <div class="operations">
              <div class="button-transactions">
                <InputNumber
                  v-model="fdAmount"
                  class="input-text"
                  placeholder="FD Amount"
                  mode="currency"
                  currency="INR"
                  :min-fraction-digits="0"
                  locale="en-US"
                  fluid
                  :min="0"
                />
                <InputNumber
                  v-model="fdTerm"
                  class="input-text"
                  placeholder="FD Term (in months)"
                  :min-fraction-digits="0"
                  fluid
                  :min="0"
                />
              </div>
              <CustomButton icon="plus" title="Create FD"  />
            </div>
            <h2 class="title text-3xl font-bold">Linked Fixed Deposit Accounts :</h2>
            <div v-if="!isLoading" class="cont">
              <DataTable paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
                <Column
                  v-for="(col, index) in fdTableColumns"
                  :key="col.field"
                  :field="col.field"
                  :header="col.header"
                  :style="col.colStyle"
                >
                  <template #body="slotProps">
                    {{ handleDataTableProps(index, slotProps.data) }}
                  </template>
                </Column>
              </DataTable>
            </div>
            <div v-else>
              <Tag severity="secondary">No Fixed Deposit Linked to this customer.</Tag>
            </div>
          </div>
          <div v-if="!customer && !isLoading" class="notFound">
            <Image :src="NotFound" width="800" />
            <Chip label="No FD found for the provided search query!" style="font-weight: 600" />
          </div>
        </template>
      </Card>
    </main>
  </template>
  

<style scoped>
.main {
  overflow-y: scroll;
}
.create-card {
  width: 100%;
  margin-bottom: 4rem;
}
.cont {
  margin: 1rem 0;
  background-color: white;
  padding: 1.5rem 1.5rem;
  border-radius: 18px;
  border: solid rgba(0, 0, 0, 0.4) 1px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.input-text {
  width: 20%;
  height: 50px;
  margin-right: 20px;
  --p-inputtext-focus-border-color: rgb(50, 95, 186);
}

.account_holder_details {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.account_details {
  padding: 16px;
  border-radius: 16px;
  background-color: #d9e2ff;
  display: flex;
  justify-content: end;
}

.text-heading {
  font-size: small;
}

.text {
  font-weight: 600;
  font-size: large;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  margin: 20px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(104, 103, 103);
  border-radius: 4px;
}
</style>
